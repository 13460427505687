<template>

	<!-- Hardware info modal -->
	<b-modal id="about-modal" centered scrollable size="lg" hide-header-close :title="$t('telestia.about.title')" no-close-on-esc no-close-on-backdrop>
		<div class="row">
			<div class="col-4">
				<img height="60" src="../../assets/images/logo.png">
			</div>
			<div class="col-6">
				<h3 >{{ description }}</h3>
				<h5>Version: {{ version }}</h5>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-6">
				<h6>{{ $t('telestia.about.computer_info') }}</h6>
				<textarea class="w-100" v-model="hardwareText" readonly></textarea>
			</div>
			<div class="col-6">
				<div v-if="isActivated" class="text-right">
					<h6>{{ $t('telestia.about.serial_number') }}</h6>
					<input type="text" class="" size="41" :value="serialNumber" readonly/><br>
					<button class="swal-button swal-button--danger font-weight-normal py-1 px-1 my-2" @click="deactivate">{{ $t('telestia.navigation.deactivate') }}</button>
				</div>
			</div>
		</div>

		<template #modal-footer>
			<b-button variant="primary" class="float-right" @click="$bvModal.hide('about-modal')" >{{ $t('telestia.button.close') }}</b-button>
		</template>
	</b-modal>

</template>

<script>
import { hardwareInfo } from "../../shared/utils/hardware";
import { description, version } from "../../../package.json";

export default {
	name: 'about',

	data() {
		return {
			languages: this.$i18n.availableLanguages,
			hardwareText: '',

			description: description,
			version: version,
		}
	},

	created() {
		this.getHardwareInfo();
	},

	computed: {
		serialNumber() { return this.$store.getters.getSerialNumber },
		isActivated() { return this.$store.getters.isActivated }
	},

	watch: {
	},

	methods: {

		show() {
			this.$bvModal.show('about-modal');
		},
		hide(){
			this.$bvModal.hide('about-modal');
		},

		async getHardwareInfo() {
			this.hardwareText = this.$t('telestia.about.loading');
			const hardware = await hardwareInfo();
			this.hardwareText =
`Manufacturer: ${hardware.manufacturer}
Baseboard: ${hardware.baseboard}
CPU: ${hardware.cpu}
RAM: ${hardware.memory}
Storage: ${hardware.storage}
Operating System: ${hardware.os}
`;
		},

		async deactivate() {
			try {
				const shouldDeactivate = await this.$swal({
					title: this.$t('telestia.about.deactivation'),
					text: this.$t('telestia.about.deactivation_msg'),
					icon: 'warning',
					buttons: [this.$t('telestia.button.cancel'), this.$t('telestia.button.yes')],
					dangerMode: true,
				});
				if (!shouldDeactivate) return;
				await this.$store.dispatch('deactivateLicense');
				await this.$store.dispatch('clearLicense');
				await this.$router.replace({ path: '/authorization' });
			} catch (e) {
				console.log(e);
				this.showErrorAlert({
					title: this.$t('telestia.error.title'),
					text: this.$t('telestia.error.deactivation'),
				});
			}
		},

		showErrorAlert({ title, text }) {
			this.$swal({ title, text, icon: 'error', timer: 15000, button: true });
		}

	}
}
</script>

<style scoped>
textarea {
	border: 0;
	outline: none;
	min-height: 160px;
	resize: none;
}
</style>

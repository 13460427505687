const state = {
  networkState: ''
};

const getters = {
  networkState: state => { return state.networkState; },
  isNetworkOnline: state => { return state.networkState === 'online'; },
  isNetworkOffline: state => { return state.networkState !== 'online'; }
};

const mutations = {
  SET_NETWORK_STATE (state, networkState) {
    state.networkState = networkState;
  },
};

const actions = {

  async initializeNetworkListener({ commit }) {
    try {
      commit('SET_NETWORK_STATE', navigator.onLine ? 'online' : 'offline');

      // Add listener for network changes
      window.addEventListener('online', () => {
        commit('SET_NETWORK_STATE', 'online');
      });
      window.addEventListener('offline', () => {
        commit('SET_NETWORK_STATE', 'offline');
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions }

<template>
  <div v-if="!inLesson" id="app-footer" class="d-flex flex-column align-items-center flex-md-row justify-content-around bg-blue text-white p-2">
    <div class="order-0 order-md-0 ml-md-2">
      <a v-if="org.footer.url" :href="org.footer.url" class="text-decoration-none text-white" target="_blank">{{ org.name }}</a>
      <span v-else>{{ org.name }}</span>
      <br>
      {{ org.footer.description }}
    </div>
    <div class="order-3 order-md-1 d-flex align-items-center">
      <a v-if="org.email || org.footer.email" class="text-decoration-none text-white" :href="'mailto:' + (org.footer.email || org.email)" role="button" title="email">
        <font-awesome-icon :icon="['fas', 'envelope']" />
      </a>
      <a v-if="org.footer.facebook" class="text-decoration-none text-white ml-2" :href="org.footer.facebook" target="_blank" role="button" title="Facebook">
        <font-awesome-icon :icon="['fab', 'facebook']" />
      </a>
      <a v-if="org.footer.instagram" class="text-decoration-none text-white ml-2" :href="org.footer.instagram" target="_blank" role="button" title="Instagram">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a v-if="org.footer.twitter" class="text-decoration-none text-white ml-2" :href="org.footer.twitter" target="_blank" role="button" title="Twitter">
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </a>
      <a v-if="org.footer.pinterest" class="text-decoration-none text-white ml-2" :href="org.footer.pinterest" target="_blank" role="button" title="Pinterest">
        <font-awesome-icon :icon="['fab', 'pinterest']" />
      </a>
      <a v-if="org.footer.youtube" class="text-decoration-none text-white mx-2" :href="org.footer.youtube" target="_blank" role="button" title="YouTube">
        <font-awesome-icon :icon="['fab', 'youtube']" />
      </a>
    </div>
    <div class="order-2 order-md-2 my-2 my-md-0 mr-md-2">
      <ul class="telephones">
        <li v-if="org.footer.tel || org.telephone">
          <font-awesome-icon :icon="['fas', 'phone']" class="mr-1" />
          <a class="text-decoration-none text-white" :href="'tel:' + (org.footer.tel || org.telephone || '').replaceAll(' ','')"> {{ org.footer.tel || org.telephone }}</a>
        </li>
        <li v-if="org.footer.mobile">
          <font-awesome-icon :icon="['fas', 'mobile']" class="mr-1" />
          <a class="text-decoration-none text-white" :href="'tel:' + (org.footer.mobile||'').replaceAll(' ','')"> {{ org.footer.mobile }} </a>
        </li>
        <li v-if="org.footer.fax">
          <font-awesome-icon :icon="['fas', 'fax']" class="mr-1" /> {{ org.footer.fax }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      default_footer: {
        'email': 'contact@etelestia.com',
        'facebook': 'https://www.facebook.com/eTelestia.OnlineClothingCourses',
        'instagram': 'https://www.instagram.com/etelestia',
        'twitter': 'https://twitter.com/eTelestia',
        'pinterest': 'https://www.pinterest.com/etelestia/',
        'youtube': 'https://www.youtube.com/user/14ismini',
        'tel': '+30 2310 251510',
        'fax': '+30 2310 251515',
      },
    };
  },

  computed: {
    inLesson() { return this.$route.name === "App:Lessons:Lesson" && this.$store.getters.getSelected.lessonKey !== null; },

    org() {
      const org = this.$store.getters['userOrganisation/getOrganisation'];
      let retOrg = JSON.parse(JSON.stringify(org));
      if (Object.keys(retOrg.footer||{}).length===0) {
        retOrg.footer = this.default_footer;
        retOrg.footer.tel = retOrg.telephone || retOrg.footer.tel;
        retOrg.footer.email = retOrg.email || retOrg.footer.email;
      }
      return retOrg;
    },
  },

}
</script>

<style scoped>
.telephones {
  list-style: none;
  margin: 0;
  padding: 0;
}
.telephones [data-icon] {
  width: 14px;
}
a:hover {
  font-weight: bold;
  transform: scale(1.4);
}
</style>


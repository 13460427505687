import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createPersistedState from "vuex-persistedstate";
import modules from './modules'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['activation', 'user', 'userOrganisation'],
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: modules,
  plugins: [createPersistedState(), vuexLocal.plugin]
})

import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const actions = {
  async fetchAnnouncements(_, orgId) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/organizations/${orgId}/announcements`);
      const announcements = response.data;

      return Promise.resolve(announcements);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchActiveAnnouncements(_, orgId) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/organizations/${orgId}/announcements/active`);
      const activeAnnouncements = response.data.announcements;

      return Promise.resolve(activeAnnouncements);
    } catch (e) {
      e.ignore = true;
      return Promise.reject(e);
    }
  }
}

export default { namespaced: true, actions }

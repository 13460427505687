import Vue from 'vue'
import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

// File server URL
const FILE_SERVER = envConfig.FILE_SERVER;

// ---------------------------------------------------------------------------------------

// Buffer with the current app state and local cache structures
const state = {

  // Content loaded from server and cached locally
  content: {
    products: {}
  },

  // User current status
  current: {
    productKey: null,
    course: null,
    menu1Index: null,
    menu2Index: null,
    lessonKey: null
  },

  // User previous status
  previous: {
    lesson: { key: null, isMainLesson: false, mainLessonPageNum: null, hasAssignment: false }
  },

  // // User next status
  // next: {
  //   productKey: null,
  //   course: null,
  //   menu1Index: null,
  //   menu2Index: null,
  //   lessonKey: null
  // },

  // Latest menu2 cursor of each menu1 item
  // latest.menu2[menu1Index] is the latest menu2 index of every menu1 item
  // We keep it here, so when user changes menu1 item, we store menu2 index from here instead of having to reset it every time
  latest: {
    menu2: {},
  },

  // App status
  status: {
    // Content details
    content: {
      // True = Content is saved locally. False = Content has not been saved yet locally (probably first time running)
      local: false,
      // True = Content was loaded and updated -if possible- from server
      initialized: false,
      // True = contacted fileserver. False = Network error
      serverContacted: false
    }
  },

  missingFilesDownload: {
    pendingDownload: false,
    total: 0,
    downloaded: 0
  },


  // App menu
  menu: {
    // All app menu items, as they are loaded from fileserver
    items: [],

    // Definitions for all menu levels, as they are loaded from fileserver
    levels: [],

    // Current menu keys. Array with level keys, one for each level, starting from level 0. Array length indicates the levels chosen
    currentKeys: [],

    // Place here a copy of currentKeys whenever selectCurrent is called (entering or leaving a menu level)
    lastCurrentKeys: []
  },

  /*
    File tree (local)
    Load/save in local cache
    Update info from server (add/remove files)
    Keeps definitions only and info of real paths to local cache
    To sync with server for a given language:
      1. Set to all files (of this language) the property synced = false
      2. Read files (of this language) from server to a temp structure (incoming)
      3. For each incoming file:
        - Set synced = true
        - Set present = false if file does not exist or version is wrong, = true if file exists and version is right
      4. Delete all entries and files (of this language) where synced=false, because server did not mention them
      5. Delete all files (of this language) where synced=true and present=false, because there is a new version to download
      6. Get all files (of this language) where synced=true and present=false
      7. Save local files info

  // Object with one property for each file, where property name = the filepath and property value is an object with properties:
  */
  files: {
    // filename:
    /* {
      language: {
        <code>: {
          filename: string // The actual filename in disk, where the file is stored. May be a hex random string
          version: string // The current version of the file, as lastly reported from server. Existing local file may contain older version or no file at all
          present: boolean // false = Local file needs to be loaded from the server. true = Local file has been loaded from the server
          synced: boolean // false = Version of local file unknown or mismatch with the server version, true = Local version is the correct one
        }
    } */
  }
}

// Get current item of a menu, using a path array of keys
//    keys: An array of keys, one for each level, starting from root level of parent
//    parent: The parent node. Levels count 0,1,2,... from this node and downwards
//    level: The level we want to get the item. If level is > keys, then return null
const getCurrentItem = (keys, parent, level) => {
  try {
    return keys.length > level && parent && parent.items ? parent.items.find(r => r.key === keys[level]) : null
  } catch (e) {
    console.log('CACHE ERROR:')
    console.log(e)
    return null
  }
}

// Get selectable children of an item
const getSelectableChildren = (keys, parent, level) => {
  return (keys.length > level) && parent ? parent.items : null
  // let item =   keys[level] getCurrentItem(keys, parent, level)
  // return item && parent.items ? parent.items : null
}

const getters = {

  // Get menu1 items
  // Current product must be selected
  // => [{index, key, title, descr}]
  getMenu1Items: (state, rootGetters) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const prd = productSkeleton || {}
    const menu1 = (prd.menu || {}).menu1 || []
    return menu1.map((m, index) => {
      const key = m.props.key;
      let translatedMenuProps = m.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
      if (translatedMenuProps) {
        const { title, description } = translatedMenuProps;
        return { index, key, title, descr: description }
      }
      translatedMenuProps = m.props;
      const { title, descr } = translatedMenuProps;
      return { index, key, title, descr }
    })
  },

  // Get menu2 items
  // Current product,menu1 must be selected
  // => [{index, title}]
  getMenu2Items: (state, rootGetters) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const prd = productSkeleton || {}
    const menu1 = (prd.menu || {}).menu1 || []
    const menu2 = (menu1[state.current.menu1Index] || {}).menu2 || []
    return menu2.map((m, index) => {
      const { w, h } = m.props;
      let translatedMenuProps = m.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
      if (!translatedMenuProps) { translatedMenuProps = m.props; }
      const { title } = translatedMenuProps;
      return { index, title, w, h }
    })
  },

  // Get menu lessons items
  // Current product, menu1, menu2 must be selected
  getMenuLessons: (state, rootGetters) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const prd = productSkeleton || {}
    const crs = (prd.courses || {})[state.current.course] || {}
    const visibles = crs.visibles || []
    const lessons = prd.lessons || {}
    const menus1 = (prd.menu || {}).menu1 || []
    const menus2 = (menus1[state.current.menu1Index] || {}).menu2 || []
    const menu2 = menus2[state.current.menu2Index] || {}
    const lessonKeys = (menu2.lessons || []) // .filter(key => visibles.includes(key)) to remove non visible lessons
    const w = menu2.props ? menu2.props.w || 90 : 90
    const h = menu2.props ? menu2.props.h || 80 : 80
    return lessonKeys.filter(key => lessons[key])
      .map(key => {
        const lesson = lessons[key] || {}
        const props = lesson.props || lesson
        return {
          key,
          enabled: visibles.includes(key),
          id: props.dbid,
          title: props.title[rootGetters["user/getSelectedLanguage"]] || props.title.en,
          image: props.image,
          w,
          h
        }
      })
  },

  getMainLessonDetails: (state, getters, rootState, rootGetters) => (lessonId) => {
  	const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
  	const lessons = productSkeleton?.lessons;
  	if (!lessons) { return { menu1Index: null, menu2: null, code: '' , hasAssignment: false }; }

  	const mainLessonKey = Object.keys(lessons).find(key => {
  		const lessonContent = lessons[key];
  		return key.includes('_ml') && lessonContent.html.languages.en.pages.some(p => p.subLessons.find(l => parseInt(l.subLessonId) === parseInt(lessonId)))
  			? true
  			: false;
  	});

  	if (!mainLessonKey) { return { menu1Index: null, menu2: null, code: '' , hasAssignment: false }; }
  	const mainLessonMenuEntries = getters.getLessonMenuEntries(mainLessonKey);
  	const mainLessonPageNum = lessons[mainLessonKey].html.languages.en.pages.findIndex(p => p.subLessons.find(l => parseInt(l.subLessonId) === parseInt(lessonId)));

    return {
      menu1Index: mainLessonMenuEntries.menu1.index,
      menu2Index: mainLessonMenuEntries.menu2.index,
      code: mainLessonKey,
      dbid: lessons[mainLessonKey].dbid || lessons[mainLessonKey].props?.dbid,
      hasAssignment: lessons[mainLessonKey].hasAssignment || lessons[mainLessonKey].props?.hasAssignment || false,
      mainLessonPageNum,
    };
  },

  // Get previously viewed lesson
  // => { key, isMainLesson, hasAssignment }
  getPreviouslyViewedLesson: (state) => {
    return state.previous.lesson;
  },

  // Get current (selected) keys and indices
  // => { product, course, menu1Index, menu2Index, lesson }
  getSelected: (state, rootGetters) => {
    const result = { ...state.current }

    // .product
    const product = rootGetters["products/getCurrentProductSkeleton"] || {}
    result.product = state.current.productKey ? product.props : {}

    // .lesson
    const lesson = state.current.lessonKey ? product.lessons[state.current.lessonKey] : {}
    result.lesson = lesson && state.current.lessonKey ? lesson.props || lesson : {}

    return result
  },

  // In case this is the first time the user studies a lesson of the currently selected course, get first lesson to study id
  getFirstLessonToStudy: (state, rootGetters) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    return productSkeleton.courses[state.current.course].lessonsOrder[0].id;
  },

  // Get current lesson key, title + menu entries
  getCurrentlyViewingLesson: (state, rootGetters) => {
    let menu1 = {};
    let menu2 = {};
    let code = '';
    let title = '';

    if (state.current.productKey && state.current.course) {
      const selectedProduct = rootGetters["products/getCurrentProductSkeleton"]
      const selectedCourseSections = selectedProduct.menu.menu1;

      const currentLesson = selectedProduct.lessons[state.current.lessonKey];
      code = state.current.lessonKey;
      title = (currentLesson.props || currentLesson).title[rootGetters["user/getSelectedLanguage"]] || (currentLesson.props || currentLesson).title.en

      if (state.current.menu1Index >= 0) {
        let menu = selectedCourseSections[state.current.menu1Index];
        let translation = menu.props.translations.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        let title = translation ? translation.title : menu.props.title;
        menu1 = { index: state.current.menu1Index, title };

        menu = selectedCourseSections[state.current.menu1Index].menu2[state.current.menu2Index]
        translation = menu.props.translations.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        title = translation ? translation.title : menu.props.title;
        menu2 = { index: state.current.menu2Index, title };
      }
    }

    return { menu1, menu2, code, title };
  },

  // Get next lesson key, title + menu entries based on current course lesson order
  getNextLesson: (state, getters, rootState, rootGetters) => (usePreviouslyViewedLesson) => {
    if (! state.current.productKey || !state.current.course) { return { menu1: {}, menu2: {}, code: '', title: '' }; }

    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]

    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder.map(l => getters.getLessonCode(l.id));

    let currentLessonOrder = -1
    if (usePreviouslyViewedLesson) {
      currentLessonOrder = courseLessonsOrdered.findIndex(l => l === state.previous.lesson.key);
    } else {
      const previousLessonOrder = courseLessonsOrdered.findIndex(l => l === state.previous.lesson.key);
      currentLessonOrder = courseLessonsOrdered.findIndex(l => l === state.current.lessonKey)
      currentLessonOrder = previousLessonOrder > currentLessonOrder
        ? previousLessonOrder + 1
        : currentLessonOrder
    }

    if (currentLessonOrder > -1 && currentLessonOrder < courseLessonsOrdered.length - 1) {
      const nextLessonCode = courseLessonsOrdered[currentLessonOrder + 1];

      return getters.getLessonMenuEntries(nextLessonCode);
    }
  },

  getLessonCode: (state, rootGetters) => (lessonId) => {
    lessonId = +lessonId;
    if (Number.isNaN(lessonId)) { return undefined; }

    const product = rootGetters["products/getCurrentProductSkeleton"]
    const lessonCodes = Object.keys(product.lessons).filter(k => (product.lessons[k].props || product.lessons[k]).dbid === lessonId);

    // There may be more than one keys for the same lesson in case of an MLFP (mtitle, mtitle_ml). In this case, return mtitle_ml if present.
    return lessonCodes.length > 1 && lessonCodes.find(code => code.includes('_ml'))
      ? lessonCodes.find(code => code.includes('_ml'))
      : lessonCodes[0];
  },

  getLessonId: (state, rootGetters) => (lessonCode) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
    const lesson = productSkeleton.lessons[lessonCode];
    return (lesson.props || lesson).dbid
  },

  // Returns true or false depending on whether the given lesson has an assignment instructions image
  hasAssignmentInstructionsImage: (state, rootGetters) => (lessonCode) => {
    // It is highly probable that the product skeleton is fetched before the state.current.productKey is updated
    // In such a case, we return true
    const currentProduct = rootGetters["products/getCurrentProductKey"];
    if (currentProduct !== state.current.productKey) { return true; }
    
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    return productSkeleton.lessons[lessonCode].hasAssignmentImage;
  },

  getLessonMenuEntries: (state, rootGetters) => (lessonCode) => {
    if (!lessonCode) { return { menu1: {}, menu2: {}, code: '' , title: '' }; }

    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const menu1 = productSkeleton.menu.menu1;

    const menuEntries = { menu1: -1, menu2: -1 }
    for (let i = 0; i < menu1.length; i++) {
      const menu2 = menu1[i].menu2;
      if (menu2) {
        for (let j = 0; j < menu2.length; j++) {
          if (menu2[j].lessons && menu2[j].lessons.findIndex(lesson => [lessonCode, `${lessonCode}_ml`].includes(lesson)) > -1) {
            const translation = menu2[j].props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
            const title = translation ? translation.title : menu2[j].props.title;
            menuEntries.menu2 = { index: j, title };
            break;
          }
        }
      }
      if (menuEntries.menu2 !== -1) {
        const translation = menu1[i].props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        const title = translation ? translation.title : menu1[i].props.title;
        menuEntries.menu1 = { index: i, title };
        break;
      }
    }

    const lessonProps = productSkeleton.lessons[lessonCode]
      ? productSkeleton.lessons[lessonCode].props || productSkeleton.lessons[lessonCode]
      : productSkeleton.lessons[`${lessonCode}_ml`].props || productSkeleton.lessons[`${lessonCode}_ml`];
    const title = lessonProps.title[rootGetters["user/getSelectedLanguage"]] || lessonProps.title.en;
    const code = productSkeleton.lessons[lessonCode] ? lessonCode : `${lessonCode}_ml`;
    return { menu1: menuEntries.menu1, menu2: menuEntries.menu2, code , title };
  },

  // Get translated course title from product skeleton for given course key => courseTitle
  getCourseTitle: (state, rootGetters) => (courseKey) => {
    // It is highly probable that the product skeleton is fetched before the state.current.productKey is updated
    // In such a case, we are sending a dummy value
    const currentProduct = rootGetters["products/getCurrentProductKey"];
    if (currentProduct !== state.current.productKey) { return '' }
    
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
    const prd = productSkeleton || {}
    const crs = prd.courses[courseKey] || {};
    const ctitleTranslation = crs.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
    const ctitle = ctitleTranslation ? ctitleTranslation.title : crs.props.title;
    return ctitle;
  },

  // Get package id for given lesson id => packageId
  getPackageId: (state, getters, rootState, rootGetters) => (lessonId) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    let lessonOrderEntry = productSkeleton.courses[state.current.course].lessonsOrder?.find(e => e.id === lessonId)

    // Use main lesson package id in case lesson Id is the currently selected lesson and previous lesson is an MLFP
    const useMainLessonPackage = state.current.lessonKey === getters.getLessonCode(lessonId) && state.previous.lesson.isMainLesson;
    if (!lessonOrderEntry && useMainLessonPackage) {
      const mainLessonId = productSkeleton.lessons[state.previous.lesson.key].dbid;
      lessonOrderEntry = productSkeleton.courses[state.current.course].lessonsOrder?.find(e => e.id === mainLessonId);
    }

    // TODO: What package_id should we return by default
    return lessonOrderEntry ? lessonOrderEntry.package_id : 1;
  },

  // Get package pdf name for given lesson code => pdfName
  getPackagePdf: (state, rootGetters) => (lessonCode) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const lessonId = productSkeleton.lessons[lessonCode]?.dbid
    const lessonOrderEntry = productSkeleton.courses[state.current.course]?.lessonsOrder?.find(e => e.id === lessonId)
    if (!lessonOrderEntry) { return ''; }

    const packageId = lessonOrderEntry.package_id;
    const lessonPackage = Object.keys(productSkeleton.lpackages)?.find(p => productSkeleton.lpackages[p].props.packageId === packageId);
    return lessonPackage ? productSkeleton.lpackages[lessonPackage].props.pdf : '';
  },

  getCoursePackagesOrder: (state, rootGetters) => {
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
    const packagesOrder = productSkeleton.courses[state.current.course]?.lpackagesOrder;
    return packagesOrder ? packagesOrder : [];
  },

  isAfter: (state, getters, rootState, rootGetters) => (lessonKey1, lessonKey2) => {
    // It is highly probable that the product skeleton is fetched before the state.current.productKey is updated
    // In such a case, we return true
    const currentProduct = rootGetters["products/getCurrentProductKey"];
    if (currentProduct !== state.current.productKey) { return true; }	
    
    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder.map(l => getters.getLessonCode(l.id));

    const lessonOrder1 = courseLessonsOrdered.findIndex(l => l === lessonKey1);
    const lessonOrder2 = courseLessonsOrdered.findIndex(l => l === lessonKey2);

    return lessonOrder1 > lessonOrder2;
  },

  isAllowedToView: (state, getters, rooState, rootGetters) => (lessonId) => {
    if (rootGetters["user/isAdmin"]) { return true; }

    const productSkeleton = rootGetters["products/getCurrentProductSkeleton"]
    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder;

    const nextToStudy = rootGetters["progress/getNextToStudy"];

    if (!nextToStudy.id || !nextToStudy.menu.code) { return true; }

    const nextToStudyIndex = courseLessonsOrdered.findIndex(l => l.id === nextToStudy.id);
    const lessonIndex = courseLessonsOrdered.findIndex(l => l.id === lessonId);

    if (lessonIndex === -1) { return getters.getLessonCode(lessonId) ? true : false; }

    return nextToStudyIndex > -1 && lessonIndex > -1 && lessonIndex <= nextToStudyIndex;
  },

  isCourseInProductSkeleton: (state, rootGetters) => (courseKey) => {
		const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
		const course = productSkeleton?.courses[courseKey];
		return course ? true : false;
	},

  isLessonInProductSkeleton: (state, getters, rootState, rootGetters) => (lessonId) => {
  	const productSkeleton = rootGetters["products/getCurrentProductSkeleton"];
  	const retrievedLessonKey = Object.keys(productSkeleton?.lessons).find(key => (productSkeleton?.lessons[key].props || productSkeleton?.lessons[key]).dbid);
  	return retrievedLessonKey ? true : false;
  },

  // Check if content+files were loaded and initialized, so that the user may begin using the app
  isInitialized: (state) => !!state.status.content.initialized,

  // Check if fileserver was contacted in the last attempt
  isServerContacted: (state) => !!state.status.content.serverContacted,

  // Check if some content has been saved locally (in local file cache) at least once
  isContentSaved: (state) => state.status.content.local,

  // Get info about local files ({$file:{language:{$code:{filename, version, present, synced}}}})
  getLocalFiles: (state) => state.files,

  // Get all menu current keys
  getMenuKeys: (state) => state.menu.currentKeys,

  // Get an array of the descriptions of the current lesson
  getCurrentLessonDescrtiption: () => {},

  // Get lesson key of previous (last) selection
  // TODO: Get full position instead of lesson only
  // => String
  getLastLesson: (state) => state.menu.lastCurrentKeys[5] || null,

  // Get info about the menu levels
  getMenuLevels: (state) => state.menu.levels,

  // Get all menu items
  getMenuItems: (state) => state.menu.items,

  // Get array of not present files
  getNotPresentFiles: (state) => () => {
    const npFiles = [];
    // For each file
    for (const [fileName, fileData] of Object.entries(state.files)) {
      // For each language
      Object.keys(fileData.language).forEach(language => {
        if (!fileData.language[language].present) { npFiles.push({fileName, language}) }
      })
    }
    return npFiles;
  },

  // Get record of current group, product, ....
  getCurrentGroup: (state) => getCurrentItem(state.menu.currentKeys, state.menu, 0),
  getCurrentProduct: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentGroup, 1),
  getCurrentCourse: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentProduct, 2),
  getCurrentMenu1: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentCourse, 3),
  getCurrentMenu2: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentMenu1, 4),
  getCurrentLesson: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentMenu2, 5),

  // Get Object with selectable items for level 0, 1, ......
  getSelectableGroups: (state) => state.menu.items.length ? state.menu.items : null,
  getSelectableProducts: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentGroup, 0),
  getSelectableCourses: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentProduct, 1),
  getSelectableMenus1: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentCourse, 2),
  getSelectableMenus2: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentMenu1, 3),
  getSelectableLessons: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentMenu2, 4),

  // Missing files
  isMissingFilesDownloadPending: (state) => state.missingFilesDownload.pendingDownload,
  getMissingFilesDownloadState: (state) => state.missingFilesDownload

}

const mutations = {

  // Select a product AND course
  selectProductCourse (state, {productKey, courseKey}) {
    state.current.productKey = productKey
    state.current.course = courseKey

    // Update local storage entry
    let savedState = localStorage.getItem("contentState");
    if (savedState) {
      savedState = JSON.parse(savedState)
      savedState.productKey = productKey;
      savedState.courseKey = courseKey;
    } else {
      const previous = { key: null, isMainLesson: false, mainLessonPageNum: null, hasAssignment: false };
      savedState = { productKey, courseKey, lessonKey: null, menu1Index: 1, menu2Index: 1, previous };
    }
    localStorage.setItem("contentState", JSON.stringify(savedState));
  },

  // Select a menu item
  selectMenu (state, items) {
    const changingMenu1 = items.hasOwnProperty('menu1Index')
    const changingMenu2 = items.hasOwnProperty('menu2Index')
    const changingLesson = items.hasOwnProperty('lessonKey')

    // Save menu2 of the existing menu1
    if (changingMenu1) { Vue.set(state.latest, state.current.menu1Index, state.current.menu2Index) }

    // Restore menu2 -if not given- of the new menu1
    if (changingMenu1 && !changingMenu2) {
      state.current.menu2Index = state.latest.hasOwnProperty(state.current.menu1Index) ? state.latest[state.current.menu1Index] : null
    }

    // Set previous lesson state prop
    if (changingLesson) {
      state.previous.lesson.key = state.current.lessonKey;

      if (items.hasOwnProperty('productSkeleton')) {
        const productSkeleton = items.productSkeleton;
        const currentLesson = productSkeleton.lessons[state.current.lessonKey]

        state.previous.lesson.hasAssignment = currentLesson && (currentLesson.props || currentLesson).hasAssignment
          ? (currentLesson.props || currentLesson).hasAssignment
          : false;
        state.previous.lesson.isMainLesson = currentLesson
          && (currentLesson.props || currentLesson).type === 'html'
          && (currentLesson.props || currentLesson).key.includes('_ml');
      }
    }

    // Set current state prop
    for (const item of ['menu1Index', 'menu2Index', 'lessonKey']) {
      if (items.hasOwnProperty(item)) { state.current[item] = items[item]; }
    }

    // Update local storage entry
    let savedState = localStorage.getItem("contentState");
    savedState = JSON.parse(savedState);
    if (!savedState) {
    	localStorage.setItem("contentState", JSON.stringify({}));
    	return;
    }

		for (const item of ['menu1Index', 'menu2Index', 'lessonKey']) {
      if (items.hasOwnProperty(item)) { savedState[item] = state.current[item]; }
    }
    if (changingLesson) {
		  savedState.previous = {
        key: state.previous.lesson.key,
        isMainLesson: state.previous.lesson.isMainLesson,
        mainLessonPageNum: state.previous.lesson.mainLessonPageNum,
        hasAssignment: state.previous.lesson.hasAssignment
      };
		}
    localStorage.setItem("contentState", JSON.stringify(savedState));
  },

  // Set previously viewed lesson props
  setPreviousLessonProps (state, previous) { state.previous.lesson = previous; },

  // Set previously viewed 'mainLessonPageNum' props
  setPreviousLessonPageNumProp (state, pageNum) { state.previous.lesson.mainLessonPageNum = pageNum; },

  // Set status of content initialized
  setInitialized (state, isInitialized) { state.status.content.initialized = !!isInitialized },

}

const actions = {

	// Restore current state property from localStorage
	async restoreCurrentProductCourseStateProps({ rootGetters, commit, dispatch }) {
		try {
			let savedState = localStorage.getItem("contentState");
		    if (savedState) {
		      savedState = JSON.parse(savedState);
		      commit('selectProductCourse', { productKey: savedState.productKey, courseKey: savedState.courseKey });
		  }

		  // Fetch product skeleton if productKey !== null
		  if (savedState.productKey) {
		    await dispatch('products/fetchProductSkeleton', savedState.productKey, { root: true });
		  }

		  return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},

  // Restore previous state property from localStorage
  restorePreviousStateProp({ commit }) {
    let savedState = localStorage.getItem("contentState");
    if (savedState) {
      savedState = JSON.parse(savedState);
      commit('setPreviousLessonProps', savedState.previous);
    }
  },


  // Mark app as initialized (user may start using the app)
  set_initialized ({ commit }, value) { commit('setInitialized', value); },

  async fetchFaqs () {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/faqs`);
      const content = response.data;
      return Promise.resolve(content.faqs);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchGlossary () {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/glossary`);
      const content = response.data;
      return Promise.resolve(content.glossary);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchInstructions () {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/instructions`);
      const content = response.data;
      return Promise.resolve(content.courseInstructions);
    } catch (e) {
      return Promise.reject(e);
    }
  }

}

export default {
  state,
  mutations,
  getters,
  actions
}


import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const state = () => ({
	organisation: {
		id: 0,
		footer: {}
	}
});

const getters = {
	getOrganisation(state){
		return state.organisation;
	}
}

const mutations = {
	organisationSave(state,organisation){
		state.organisation = organisation;
	}
}

const actions = {
	async fetchOrganisation({ dispatch }, id) {
		try {
			const response = await axios.get(`${FILE_SERVER}/organizations/${id}`);
			dispatch('setOrganisation', response.data);
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},

	setOrganisation({ commit }, organisation) {
		try {
			if (typeof organisation.footer == 'string') {
				organisation.footer = JSON.parse(organisation.footer||'{}');
			}
		} catch (e) {
			state.organisation.footer = {};
		}
		commit('organisationSave', organisation);
	},

}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}

import envConfig from "../configs/config.local"
import axios from "axios";
import store from "../../store/index";

const axiosConfig = {
  baseURL: envConfig.FILE_SERVER,
  timeout: 30000,
};

axios.interceptors.request.use(
  config => {
    const token = store.getters['user/getToken']
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => {
    store.commit('network/SET_NETWORK_STATE', 'online');
    return response;
  },
  error => {
    store.commit('network/SET_NETWORK_STATE', 'offline');
    return Promise.reject(error);
  },
);

export default axios.create(axiosConfig)

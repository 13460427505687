import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './shared/filters/filters'

Vue.config.productionTip = false

// ===================
// Bootstrap vue
// ===================
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// ===================
// Fontawesome icons
// ===================
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab, faPinterest } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, fab, faPinterest)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// ===================
// Auth
// ===================
// import {local} from "./shared/configs/config.local"
// Vue.prototype.$requiredLogin  = local.auth.required.login
// Vue.prototype.$requiredActivation = local.auth.required.activation

// ===================
// Axios
// ===================
import axios from "./shared/plugins/axios";
Vue.prototype.$axios = axios

// ===================
// Swal
// ===================
import VueSwal from 'vue-swal'
Vue.use(VueSwal)

// ===================
// Form wizard
// ===================
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

import {getViewport} from './shared/utils/viewport';
Vue.prototype.$getViewport = getViewport;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

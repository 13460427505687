import envConfig from '../../shared/configs/config.local';
import store from '../../store/index';
import router from '../../router';

const security = {

	checkLicense: async function(){
		if (!envConfig.auth.required.activation) return;

		const MIN = 60 * 1000;
		const DAY = 24 * 60 * MIN;
		const lastCheck = store.getters.getLastCheck || 0;
		//run check at most every 10 minutes
		if ((lastCheck+(10*MIN))>Date.now()) return;

		const isActivationValid = await store.dispatch('checkAppActivation');
		if (!isActivationValid) return await this.clearLicense();

		try {
			const isLicenseValid = await store.dispatch('checkLicense');
			if (!isLicenseValid) {
				await this.clearLicense();
			} else {
				store.commit('setLastCheck', Date.now());
			}
		} catch (e) {
			console.log(e);
			if ((lastCheck+(14*DAY))<Date.now()) {
				//if last check was more than 14 days ago, logout to force internet
				this.logout();
			}
		}
	},

	checkSession: async function(){
		if (!envConfig.auth.required.login) return;
		const data = await store.dispatch('user/fetchSessionId');
		if (data===false) return;
		if (data.code || !data.sessionId) return this.logout();
		if (envConfig.auth.singleLogin && (data.sessionId!==store.getters['user/getSessionId'])) {
			this.logout('Account in use in another location. Please re-login.');
		}
	},

    logout(error) {
    	store.dispatch('user/clearSession', {});
	    router.push({ name: 'authorization-page', params: { error } });
    },

    clearLicense: async function(){
        await store.dispatch('clearLicense');
        this.logout();
    },

};

export default security;


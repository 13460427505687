import axios from 'axios';
import { generateRandomAlphaNumericString } from "../../shared/utils/generic";

const STORED_REQUESTS_CACHE_KEY = 's_rqs';

const actions = {

  async init({ dispatch }) {
    try {
      await dispatch('sendStoredRequests');
      window.addEventListener('online', () => {
        dispatch('sendStoredRequests');
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async storeRequest(ctx, request) {

    request = {
      ...request,
      id: generateRandomAlphaNumericString(),
      time: Date.now()
    };

    try {
      const storedRequests = await getStoredRequests();
      storedRequests.push({...request});

      localStorage.setItem(STORED_REQUESTS_CACHE_KEY, JSON.stringify(storedRequests));
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  },

  async sendStoredRequests({ rootGetters }) {
    try {
      const isNetworkOnline = rootGetters['network/isNetworkOnline'];
      const storedRequests = await getStoredRequests();
      const failedRequests = [];
      if (storedRequests.length > 0) {
        for (const req of storedRequests) {
          if (isNetworkOnline) {
            try {
              await axios.request({ method: req.method, url: req.url, data: req.data });
            } catch (e) {
              failedRequests.push(req);
            }
          } else {
            failedRequests.push(req);
          }
        }
      }
      failedRequests.length > 0
        ? localStorage.setItem(STORED_REQUESTS_CACHE_KEY, JSON.stringify(failedRequests))
        : localStorage.removeItem(STORED_REQUESTS_CACHE_KEY);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

};

// ================================================================================
// Helpers
// ================================================================================

async function getStoredRequests() {
  try {
    let storedRequests = JSON.parse(localStorage.getItem(STORED_REQUESTS_CACHE_KEY));
    if (!storedRequests) { storedRequests = []; }
    return Promise.resolve(storedRequests);
  } catch (e) {
    return Promise.reject();
  }
}

export default { namespaced: true, actions }

import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const actions = {
  async fetchUserAssignments({ commit }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/assignments`);
      const assignments = response.data.assignments;

      // Return assignments
      return Promise.resolve(assignments);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  
  async fetchUserAssignmentById({ commit }, id) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/assignments/${id}`);
      const assignment = response.data;

      // Return assignment
      return Promise.resolve(assignment);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  
  async fetchUploadedAssignmentsByCourseId({ commit, rootGetters }, courseId) {
    try {
      // Fetch
      const params = { language: rootGetters["user/getSelectedLanguage"] };
      const response = await axios.get(`${FILE_SERVER}/user/assignments/uploaded/${courseId}`, { params });
      const assignments = response.data;
      
      // Return uploaded assignments
      return Promise.resolve(assignments);
    } catch (e) {
    	return Promise.reject(e);
    }	
  },

  async fetchUserAssignmentsByCourseId({ commit }, courseId) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/assignments/course/${courseId}`);
      const assignments = response.data;

      // Return assignments
      return Promise.resolve(assignments);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async uploadUserAssignment({ commit }, assignmentFormData) {
    try {
      // Upload assignment
      const response = await axios.post(`${FILE_SERVER}/user/assignments`, assignmentFormData);
      const newUpload = response.data;

      // Return new assignment upload
      return Promise.resolve(newUpload);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchAssignmentInstructions({ commit, rootState }, lessonKey) {
    try {
      // Fetch assignment instructions
      const productKey = rootState.cache.current.productKey.toLowerCase();
      const response = await axios.get(`${FILE_SERVER}/user/assignments/image`, { params: { product: productKey, title: lessonKey } });
      const instructionsBase64 = response.data;

      // Return assignment instructions in Base 64 format
      return Promise.resolve(instructionsBase64)
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default { namespaced: true, actions }

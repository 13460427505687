<template>
    <div id="login-container" style="height: 100vh;">
      <div class="row d-flex justify-content-center align-items-center m-0">
        <div class="text-center col-12 py-5">
          <img class="main-logo mouse-pointer mw-100" src="../../assets/images/logo.png" :alt="description" height="65"/>
          <h1 class="title font-weight-bold">{{ description }}</h1>
        </div>
      </div>

      <div class="row d-flex justify-content-center align-items-center m-0">
        <div class="col-12 col-md-6 col-lg-5 py-1 d-flex justify-content-between">
          <div class="d-flex">&nbsp;</div>
          <div class="d-flex"><language-select always-flag="true"/></div>
        </div>
      </div>

      <div v-if="!forgotPassword" class="row d-flex justify-content-center align-items-center m-0">
        <div class="col-12 col-md-6 col-lg-5 pb-5">
          <div class="card bg-light">
            <div class="card-body p-5">
              <b-form @submit.prevent="onSubmitLogin">
                <p class="h3 mb-5 text-center">{{ $t('telestia.login.title') }}</p>

                <b-form-group class="mb-2" id="usernameInputGroup" label-for="usernameInput">
                  <b-form-input
                      id="usernameInputGroup"
                      v-model="username"
                      autocomplete="username"
                      required
                      :placeholder="$t('telestia.login.username')"/>
                </b-form-group>

                <b-form-group id="passwordInputGroup" label-for="passwordInput">
                  <b-form-input
                      id="passwordInputGroup"
                      type="password"
                      autocomplete="current-password"
                      v-model="password"
                      required
                      :placeholder="$t('telestia.login.password')"/>
                </b-form-group>

                <div class="text-center">
                  <b-button type="submit" variant="primary" class="mt-5">
                    <b-spinner v-if="loading" type="grow" variant="info" />
                    <p v-else id="submit-text" class="mb-0 p-1">{{ $t('telestia.login.title') }}</p>
                  </b-button>
                </div>
              </b-form>
            </div>
            <div class="card-footer p-2 text-right">
              <a href="javascript:;" @click="forgotPassword=true">{{ $t('telestia.login.forgot') }}</a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="forgotPassword" class="row d-flex justify-content-center align-items-center m-0">
        <div class="col-12 col-md-6 col-lg-5 pb-5">
          <div class="card bg-light">
            <div class="card-body p-5">
              <b-form @submit.prevent="onSubmitForgotPassword">
                <p class="mb-3">{{ $t('telestia.login.forgot_prompt') }}</p>

                <b-form-group class="mb-2" id="usernameInputGroup" label-for="usernameInput">
                  <b-form-input
                      id="usernameInputGroup"
                      v-model="emailOrUsername"
                      required
                      :placeholder="$t('telestia.login.email')"/>
                </b-form-group>

                <div class="text-center">
                  <b-button type="submit" variant="primary" class="mt-5">
                    <b-spinner v-if="loading" type="grow" variant="info" />
                    <p v-else id="submit-text" class="mb-0 p-1">{{ $t('telestia.button.ok') }}</p>
                  </b-button>
                </div>
              </b-form>
            </div>
            <div class="card-footer p-2 text-right">
              <a href="javascript:;5" @click="forgotPassword=false">&lt;&lt; {{ $t('telestia.login.back') }}</a>
            </div>
          </div>
        </div>
      </div>

      <about ref="about" />
      <b-button v-if="!isOnlineBuild" id="aboutButton" variant="primary" @click="showAbout">
        <font-awesome-icon size="lg" :icon="['fas','question']"/><br>
      </b-button>
    </div>

</template>


<script>
import About from '../shared/About.vue';
import { description } from '../../../package.json';
import LanguageSelect from '../shared/LanguageSelect.vue';

export default {
  name: 'login',

  components: {
    About,
    LanguageSelect,
  },

  data: function () {
    return {
      username: '',
      password: '',
      emailOrUsername: '',
      description,

      loading: false,
      user: false,
      error: '',

      forgotPassword: false,
    }
  },

  mounted() {
    if (this.$route.params.error) {
      this.$swal({ title: '', text: this.$route.params.error, icon: 'error', timer: 10000, button: true });
    }
  },

  computed: {
    isOnlineBuild() { return this.$store.getters["app/isOnline"] },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage'] },
  },

  methods: {
    async onSubmitLogin() {
      try {
        this.loading = true
        await this.$store.dispatch("user/login", { username: this.username, password: this.password })
        this.$router.replace({ name: 'App:Main' })
      } catch(e) {
        console.log(e);
        if (e.message && e.message === "login/unexpected_error") {
          this.$swal({ title: this.$t('telestia.error.title'), text: this.$t('telestia.error.connection'), icon: 'error', timer: 6000, button: true })
        } else if (e.status === 404) {
          this.$swal({ title: this.$t('telestia.error.title'), text: this.$t('telestia.login.wrong'), icon: 'error', timer: 6000, button: true })
        } else {
          this.$swal({ title: this.$t('telestia.error.title'), text: this.$t('telestia.error.connection'), icon: 'error', timer: 6000, error: e, button: true })
        }
      } finally {
        this.loading = false;
      }
    },

    async onSubmitForgotPassword() {
      try {
        this.loading = true
        await this.$store.dispatch('user/forgotPassword', { emailOrUsername: this.emailOrUsername, language: this.selectedLanguage });
      } catch(e) {
        console.log(e);
      } finally {
        this.$swal({ text: this.$t('telestia.login.forgot_retrieve'), icon: 'info', timer: 10000, button: true });
        this.forgotPassword = false;
        this.loading = false;
        this.emailOrUsername = '';
      }
    },

    showAbout() {
      this.$refs.about.show();
    },

  }
}
</script>

<style scoped>
.title {
  color: #1a96cf;
}

#aboutButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: auto;
}
</style>


<template>
  <div id="authorization-container">
    <login  v-if="showLogin"/>
    <activation-wizard v-if="showActivation" class="mt-2"/>
  </div>

</template>


<script>

import Login from "../../components/Authorization/Login";
import ActivationWizard from "../../components/Authorization/ActivationWizard";
import envConfig from "../../shared/configs/config.local";

export default {
  name: 'authorization',

  components: {
    Login,
    ActivationWizard
  },

  data() {
    return {
      requireLogin: envConfig.auth.required.login,
      requireActivation: envConfig.auth.required.activation
    }
  },

  computed: {
    showLogin: function () {
      if(this.requireLogin && !this.requireActivation){
        return true
      } else return !!(this.requireLogin && (this.requireActivation && this.$store.getters.isActivated));
    },
    showActivation: function () {
      if(!this.isElectron()) return false

      return !!(this.requireActivation && !this.$store.getters.isActivated);
    }
  },

  methods: {
    isElectron: function () {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.indexOf(' electron/') > -1;
    }
  },
}
</script>

// A simple encoder to encrypt files using streams
// TODO:
//  Implement decoder
//  Experiment with encoding methods
//  Pass keys from application

let { Transform } = require('stream')

const KEYCODE = [7, 34, 41, 42, 60, 150, 201, 228]

// Select one of the methods below
const METHOD = 'noencoding'

// Methods to encode, decode
const methods = {
  // Plain transfer - no encoding
  noencoding: {
    initEncode: () => {},
    initDecode: () => {},
    encode: (buf) => buf,
    decode: (buf) => buf
  },

  // Experimental xor with multiple running keys
  xor1: {
    initEncode: () => ({ ctr: [ ...KEYCODE ] }),
    initDecode: () => ({ ctr: [ ...KEYCODE ] }),
    encode: (buf, state) => {
      let res = Buffer.from(buf)
      res.forEach((b, i) => {
        state.ctr.forEach((c, j) => {
          b = b ^ c
          state.ctr[j] = (state.ctr[j] + 1) % 256
        })
        res[i] = b
      })
      return res
    },
    decode: (buf, state) => {
      let res = Buffer.from(buf)
      res.forEach((b, i) => {
        state.ctr.forEach((c, j) => {
          b = b ^ c
          state.ctr[j] = (state.ctr[j] + 1) % 256
        })
        res[i] = b
      })
      return res
    }
  }

}

module.exports = {
  // Function that returns a stream Transform function to encode (encrypt) stream contents
  Encoder: () => {
    // TODO: Pass a unique key (stream filename for example) to generate a ctr from a hash of the key
    let m = methods[METHOD]
    let state = m.initEncode()

    // Prepare the encoder
    return new Transform({
      transform (chunk, encoding, next) {
        let buf = m.encode(chunk, state)
        next(false, buf)
      }
    })
  },

  // Function that returns a stream Transform function to decode (decrypt) stream or buffercontents
  Decoder: (mode = 'stream') => {
    // TODO: Pass a unique key (stream filename for example) to generate a ctr from a hash of the key
    let m = methods[METHOD]
    let state = m.initDecode()

    // Return the (stream of buffer) decoder
    switch (mode) {
      case 'stream':
        return new Transform({
          transform (chunk, encoding, next) {
            let buf = m.decode(chunk, state)
            next(false, buf)
          }
        })
      case 'buffer':
        return (chunk) => m.decode(chunk, state)
    }
  }
}

import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  defaultMeasurement: null
}

const getters = {
  getDefaultMeasurement(state) {
    return state.defaultMeasurement;
  }
}

const mutations = {
  setDefaultMeasurement(state, measurement) {
    state.defaultMeasurement = measurement;
  }
}

const actions = {
  async fetchUserMeasurements({ commit }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/measurements`);
      const measurements = response.data.measurements;

      // Return measurements
      return Promise.resolve(measurements);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchDefaultUserMeasurement({ getters, commit }) {
    const defaultMeasurement = getters.getDefaultMeasurement;
    if (defaultMeasurement) { return defaultMeasurement; }

    try {
      // Fetch default measurement
      const response = await axios.get(`${FILE_SERVER}/user/measurements/default`);
      const defaultMeasurement = response.data;
      // const defaultMeasurement = {
      //   id: 11, user_id: 1, name: "Mymeas5", TCM: 3, TBM: 3, SDF: 3, SDB: 3, TBL: 3, BLF: 3, BLB: 3, BL: 5, BD: 23, SL: 3, SkL: 3, WM: 3, HM: 3, Slant: 3, is_default: 1
      // };
      commit("setDefaultMeasurement", defaultMeasurement);

      // Return measurements
      return Promise.resolve(defaultMeasurement);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addUserMeasurement({ commit }, measurement) {
  	try {
      // Add measurement
      const response = await axios.post(`${FILE_SERVER}/user/measurements`, measurement);
      const newMeasurement = response.data

      // Update default measurement if needed
      if (newMeasurement.is_default === 1) { commit("setDefaultMeasurement", newMeasurement); }

      // Return new measurement
      return Promise.resolve(newMeasurement)
    } catch (e) {
    	return Promise.reject(e);
    }
  },

  async deleteUserMeasurement({ commit }, measurementId) {
  	try {
      // Delete measurement
      await axios.delete(`${FILE_SERVER}/user/measurements/${measurementId}`);

      // Update default measurement if needed
      const defaultMeasurement = getters.getDefaultMeasurement;
      if (defaultMeasurement && defaultMeasurement.id === measurementId) { commit("setDefaultMeasurement", null); }

      // Return new measurement
      return Promise.resolve()
    } catch (e) {
    	return Promise.reject(e);
    }
  },

  async editUserMeasurement({ commit }, measurement) {
  	try {
      // Edit measurement
      const response = await axios.put(`${FILE_SERVER}/user/measurements/${measurement.id}`, measurement);
      const updatedMeasurement = response.data;

      // Update default measurement if needed
      const defaultMeasurement = getters.getDefaultMeasurement;
      if (updatedMeasurement.id === defaultMeasurement.id && updatedMeasurement.is_default === 0) {
        commit("setDefaultMeasurement", null);
      } else if (updatedMeasurement.is_default === 1) {
        commit("setDefaultMeasurement", updatedMeasurement);
      }

      // Return edited measurement
      return Promise.resolve(updatedMeasurement)
    } catch (e) {
    	return Promise.reject(e);
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }

import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const actions = {
    async fetchLessonContent({ commit }, { productKey, lessonKey }) {
        try {
            // Fetch
            const response = await axios.get(`${FILE_SERVER}/products/${productKey.toLowerCase()}/lessons/${lessonKey}`);
            const lessonContent = response.data;

            // Return lesson content
            return Promise.resolve(lessonContent);
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async fetchLessonMenuIcon({ commit }, { productKey, title }) {
        try {
            // Fetch
            const params = { product: productKey.toLowerCase(), title }
            const response = await axios.get(`${FILE_SERVER}/menu/icons`, { params });
            const lessonIcon = response.data;

            // Return lesson menu icon
            return Promise.resolve(lessonIcon);
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async addNewVideoTranslation({ commit }, newTranslation) {
        try {
            // Add translation
            await axios.post(`${FILE_SERVER}/new-video/texts`, newTranslation);

            // Resolve
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async editNewVideoTranslation({ commit }, translation) {
        try {
            // Edit translation
            await axios.put(`${FILE_SERVER}/new-video/texts`, translation);

            // Resolve
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async fetchLessonAsset({ commit }, { product, title }) {
        try {
            // Fetch
            const params = { product, title }
            const response = await axios.get(`${FILE_SERVER}/assets/fetchData`, { params });
            const lessonAsset = response.data;

            // Return lesson menu icon
            return Promise.resolve(lessonAsset);
        } catch (e) {
            return Promise.reject(e);
        }
    },
}

export default { namespaced: true, actions }


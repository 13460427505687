import envConfig from '../../shared/configs/config.local.js'
import axios from 'axios';

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  lastStudied: {},
  nextToStudy: {},
  currentCourseState: {
  	started: true,
  	finished: false
  }
}

const getters = {
  getLastStudied(state) {
    return state.lastStudied;
  },

  getNextToStudy(state) {
    return state.nextToStudy;
  },

  getCurrentCourseState(state) {
  	return state.currentCourseState;
  }
}

const mutations = {
  setLastStudied(state, lastStudied) {
    state.lastStudied = lastStudied;
  },

  setNextToStudy(state, nextToStudy) {
    state.nextToStudy = nextToStudy;
  },

  updateLastStudied(state, lastStudiedLessonMenuInfo) {
    state.lastStudied.menu = lastStudiedLessonMenuInfo
  },

  updateNextToStudy(state, nextToStudyMenuInfo) {
    state.nextToStudy.menu = nextToStudyMenuInfo;
  },

  setCurrentCourseState(state, { started, finished }) {
  	state.currentCourseState.started = started;
  	state.currentCourseState.finished = finished;
  }
}

const actions = {
	async fetchProgress({ commit }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/progress`);
      const progress = response.data.progress;

      // Return progress
      return Promise.resolve(progress);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchProgressByLessonId({ commit }, lessonId) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/progress/${lessonId}`);
      const progress = response.data;

      // Return progress
      return Promise.resolve(progress);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addLessonSession({ rootGetters, dispatch }, lessonId) {
    try {
      const isNetworkOnline = rootGetters['network/isNetworkOnline'];
      const url = `${FILE_SERVER}/user/progress/${lessonId}/session`;
      
      const payload = { lesson_id: lessonId, package_id: rootGetters['getPackageId'](lessonId) };
      if (isNetworkOnline) {
        await axios.put(url, payload);
      } else {
        await dispatch('offline-manager/storeRequest', { method: 'put', url, data: payload }, { root:true })
      }
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async markLessonAsComplete({ rootGetters, dispatch }, lessonId) {
  	try {
      const isNetworkOnline = rootGetters['network/isNetworkOnline'];
      const url = `${FILE_SERVER}/user/progress/${lessonId}/complete`;
      if (isNetworkOnline) {
        await axios.put(url);
      } else {
        await dispatch('offline-manager/storeRequest', { method: 'put', url, data: {} })
      }
      return Promise.resolve()
    } catch (e) {
    	return Promise.reject(e);
    }
  },

  async fetchTodaysClass({ commit, rootGetters }, courseId) {
    try {
      // Fetch
      const params = { language: rootGetters["user/getSelectedLanguage"] };
      const response = await axios.get(`${FILE_SERVER}/user/progress/today/${courseId}`, { params });
      const todaysClass = response.data;

      // Return todaysClass
      return Promise.resolve(todaysClass);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchLastNextToStudy({ commit, rootGetters }, courseId) {
    try {
    	// Initialize currentCourseState
    	commit("setCurrentCourseState", { started: true, finished: false });
      // Initialize last studied and next to study
      commit("setLastStudied", {});
      commit("setNextToStudy", {});

      // Fetch
      const requests = [
        axios.get(`${FILE_SERVER}/user/progress/previousLesson/${courseId}`),
        axios.get(`${FILE_SERVER}/user/progress/nextLesson/${courseId}`),
      ]
      const results = await Promise.allSettled(requests);
      if (results.some(r => r.status === 'rejected' && r.reason.message === 'Network Error')) { return Promise.reject(); }

      const data = { lastStudied: {}, nextToStudy: {} };
      Object.keys(data).forEach((key, index) => {
        if (results[index].status !== 'rejected') { data[key] = results[index].value.data[0] ? results[index].value.data[0] : data[key]; }

        if (data[key].id) {
          const lessonCode = rootGetters["getLessonCode"](data[key].id);
          const menuEntries = rootGetters["getLessonMenuEntries"](lessonCode);
          data[key].menu = menuEntries;
        }
      })
      if (results[0].status !== 'rejected' && results[0].value.data.length === 0) { commit("setCurrentCourseState", { started: false, finished: false }); }
      if (results[1].status !== 'rejected' && results[1].value.data.length === 0) { commit("setCurrentCourseState", { started: true, finished: true }); }

      commit("setLastStudied", data.lastStudied);
      commit("setNextToStudy", data.nextToStudy);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  updateLastStudiedLesson({ commit }, lastStudiedMenuInfo) {
    commit("updateLastStudied", lastStudiedMenuInfo);
  },

  updateNextLessonToStudy({ commit }, nextToStudyMenuInfo) {
    commit("updateNextToStudy", nextToStudyMenuInfo);
  }
}

export default { namespaced: true, state, getters, mutations, actions }

/**
 * Reads file's content as base64
 * @param file File to be read
 * @returns {Promise<string>}
 */
export async function readFileAsDataURL(file) {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  })
}

/**
 * Converts buffer data of a file to a blob
 * @param Array buffer data
 * @returns {Blob}
 */
export function bufferDataToBlob(data) {
	const dataLength = data.length;
	const bytes = new Uint8Array(dataLength);
	for (let i = 0; i < dataLength; i++) { bytes[i] = data[i]; }
	const blob = new Blob([bytes.buffer]);
	
	return blob;
}

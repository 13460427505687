const systemInformation = require('systeminformation')
const crypto = require('crypto')
// const request = require('request')
// const qs = require('querystring')

const SECRET1 = 'Vou112Sitam-abIsmini'
const SECRET2 = 'eTelestiaVou3Egnatia'
// const URL_CONNECTION = 'https://www.etelestia.com/etelestia/application/connection.aspx'
// const URL_CHECKREG = 'https://www.etelestia.com/etelestia/application/checkreg.aspx'
// const URL_CHECKLIC = 'https://www.etelestia.com/etelestia/application/checklic.aspx'


const activation = {

  calcActivation: function(serialNumber, computerId, licenseId) {
    // console.log('calcActivation', serialNumber, computerId, licenseId, crypto.createHash('md5').update(SECRET2 + serialNumber + computerId + licenseId + '15-1').digest('hex'))
    return crypto.createHash('md5').update(SECRET2 + serialNumber + computerId + licenseId + '13-1').digest('hex')
  },
  
  calculateComputerId: async function() {
    const serialSystem =  (await systemInformation.system()).serial || '';
    const serialBaseboard = (await systemInformation.baseboard()).serial || '';
    const serialMemory = (await systemInformation.memLayout())[0].serialNum || '';
    const serialDisk = ((await systemInformation.diskLayout()).filter(disk => disk.interfaceType!='USB' && disk.type!='Unspecified'))[0].serialNum || '';

    const computerSerial = serialSystem + serialBaseboard + serialMemory + serialDisk;
    return crypto.createHash('sha256').update(computerSerial).digest('hex');
  },

  calculateLicenceId: function (computerId, serialNumber) {
    return crypto.createHash('md5').update(computerId + Date.now() + serialNumber).digest('hex')
  },

  checkLicense: function (serialNumber, computerId, licenseId, activationId) {
    let calculatedActivation = this.calcActivation(serialNumber, computerId, licenseId)
    return activationId === calculatedActivation
  },

  calculateActivationHashParam: function (serialNumber, computerId, licenseId) {
    return crypto.createHash('md5').update(serialNumber + computerId + licenseId + SECRET1).digest('hex')
  },

  // checkRegistrationOnline: async function (serialNumber) {
  //
  //   return new Promise((resolve, reject) => {
  //     console.warn('TEST URL', URL_CHECKREG + '?' + qs.stringify({a: serialNumber}))
  //
  //     request.post({
  //       url: URL_CHECKREG + '?' + qs.stringify({a: serialNumber})
  //     }, (error, res, body) => {
  //       console.log('HTTP RESPONSE', error, res)
  //       if (!error && res.statusCode === 200) {
  //         console.warn('HTTP BODY', body)
  //         if (body !== '') {
  //           console.log('OK')
  //           resolve(body)
  //         } else {
  //           console.log('NO')
  //           resolve(false)
  //         }
  //       } else {
  //         reject(res.statusCode)
  //       }
  //     })
  //   })
  // },

  // checkLicenseOnline: async function (activationId) {
  //
  //   return new Promise((resolve, reject) => {
  //     console.warn('TEST URL', URL_CHECKLIC + '?' + qs.stringify({a: activationId}))
  //
  //     request.post({
  //       url: URL_CHECKLIC + '?' + qs.stringify({a: activationId})
  //     }, (error, res, body) => {
  //       console.log('HTTP RESPONSE', error, res)
  //       if (!error && res.statusCode === 200) {
  //         console.warn('HTTP BODY', body)
  //         let check = body.split(/\r?\n/, 1)[0]
  //         console.log('downloaded check', check)
  //         if (check === crypto.createHash('md5').update(SECRET2 + activationId).digest('hex')) {
  //           console.log('OK')
  //           resolve(activationId)
  //         } else {
  //           console.log('NO')
  //           resolve(false)
  //         }
  //       } else {
  //         reject(res.statusCode)
  //       }
  //     })
  //   })
  // },

  // downloadActivation: async function (serialNumber, computerId, registered, fullName, address, country, telephone, email) {
  //   let licenseId = this.calculateLicenceId(this.computerId, this.license)
  //   store.dispatch('setLicenseId', licenseId)
  //
  //   let payload = {
  //     a: serialNumber,
  //     b: computerId,
  //     c: licenseId,
  //     d: crypto.createHash('md5').update(serialNumber + computerId + licenseId + SECRET1).digest('hex'),
  //     f: registered ? 1 : 0
  //   }
  //   if (registered) {
  //     payload["form"] = {}
  //   } else {
  //     payload["form"]["fullname"] = fullName
  //     payload["form"]["address"]  = address
  //     payload["form"]["country"]  = country
  //     payload["form"]["tel"]      = telephone
  //     payload["form"]["email"]    = email
  //   }
  //
  //   return new Promise((resolve, reject) => {
  //
  //     request.post({
  //       url: URL_CONNECTION + '?' + qs.stringify({
  //         a: serialNumber,
  //         b: computerId,
  //         c: licenseId,
  //         d: crypto.createHash('md5').update(serialNumber + computerId + licenseId + SECRET1).digest('hex'),
  //         f: registered ? 1 : 0
  //       }),
  //       form: !registered ? {} : {
  //         fullname: fullName,
  //         address: address,
  //         country: country,
  //         tel: telephone,
  //         email: email
  //       }
  //     }, (error, res, body) => {
  //       console.log('HTTP RESPONSE', error, res)
  //       if (!error && res.statusCode === 200) {
  //         console.warn('HTTP BODY', body)
  //         let aid = body.split(/\r?\n/, 1)[0]
  //         console.log('downloaded aid', aid, calcActivation(serialNumber, computerId, licenseId))
  //         resolve(aid)
  //       } else {
  //         reject(res.statusCode)
  //       }
  //     })
  //   })
  // },
}

export default activation;

// 3a3b5fc29da1fc6c28997607f77e75445d4c1e77


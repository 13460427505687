import i18n from "../../i18n";
import envConfig from "../../shared/configs/config.local";

const state = {
  platform: '', // electron | web
  buildType: '', // offline | hybrid | online
  rtl: false,

  pendingCourseSelection: false,
  pendingCourseProgressFetch: false,

  navigationHeaderKey: 0,
  
  // the user is requesting to view a lesson directly from a URL
  navigatingToLessonViaHardlink: false
}

const getters = {

  platform: state => { return state.platform },

  isElectron: state => { return state.platform === 'electron' },

  buildType: state => { return state.buildType },

  isOffline: state => { return state.buildType === 'offline' },

  isHybrid: state => { return state.buildType === 'hybrid' },

  isOnline: state => { return state.buildType === 'online' },
  
  isRtl: state => { return state.rtl },

  getCourseSelectionState: state => { return state.pendingCourseSelection; },

  getCourseProgressFetchState: state => { return state.pendingCourseProgressFetch; },

  getNavigatingToLessonViaHardlink: state => { return state.navigatingToLessonViaHardlink; },

  getAppLoadingMessage: (state, getters) => {
    if (getters.getNavigatingToLessonViaHardlink) {
      return 'Navigating to requested page';
    } else if (getters.getCourseSelectionState && getters.getCourseProgressFetchState) {
      return 'Selecting course, fetching course progress';
    } else if (getters.getCourseSelectionState) {
      return 'Selecting course';
    } else {
      return 'Fetching course progress';
    }
  },

  navigationHeaderKey: state => { return state.navigationHeaderKey },

}

const mutations = {

  setPlatform (state) {
    const userAgent = navigator.userAgent.toLowerCase()
    state.platform = userAgent.indexOf(' electron/') > -1 ? 'electron' : 'web'
  },

  setBuildType (state, type) {
    state.buildType = type;
  },

  setCourseSelectionState (state) { state.pendingCourseSelection = !state.pendingCourseSelection; },

  setCourseProgressFetchState (state) { state.pendingCourseProgressFetch = !state.pendingCourseProgressFetch; },
  
  setNavigatingToLessonViaHardlink (state, navigatingToLessonViaHardlink) { state.navigatingToLessonViaHardlink = navigatingToLessonViaHardlink; },

  changeLocale (state, locale) {
    i18n.loadLanguageAsync(locale)
  },

  setNavigationHeaderKey (state, key) {
    state.navigationHeaderKey = key;
  },
  
  setRtl (state, rtl) {
    state.rtl = rtl;
  }

}

const actions = {

  // TODO: Set proper build type
  initializeAppState ({ commit, dispatch }) {
    commit('setPlatform');
    commit('setBuildType', envConfig.buildType);

    dispatch("setAppLocale");
  },

  toggleCourseSelectionState ({ commit }) { commit('setCourseSelectionState'); },

  toggleCourseProgressFetchState ({ commit }) { commit('setCourseProgressFetchState'); },

  setAppLocale({ dispatch, rootGetters }) {
    const locale = rootGetters["user/getSelectedLanguage"];
    if (locale) { dispatch("changeAppLocale", locale); }
  },

  changeAppLocale({ commit }, payload) { commit('changeLocale', payload); },
  
  setRtlProp({ commit }, rtl) { commit('setRtl', rtl); },

  changeNavigationHeaderKey({ commit }, key) { commit('setNavigationHeaderKey', key); },

}

export default { namespaced: true, state, getters, mutations, actions }

<template>
	<b-button id="btnScrollToTop" variant="primary" :class="{ visible: visible }" @click="scrollToTop">
		<font-awesome-icon size="lg" :icon="['fas','arrow-up']"/><br>
	</b-button>
</template>

<script>
export default {
	name: 'scrolltotop',

	data() {
		return {
			visible: false,
		};
	},

	mounted() {
		window.onscroll = ()=>{
			this.visible = (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30);
		};
	},

	methods: {
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
	},
}
</script>

<style scoped>
#btnScrollToTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	opacity: 0;
	transition: all .4s ease-in-out;
	pointer-events: none;
}
#btnScrollToTop.visible {
	opacity: 1;
	pointer-events: initial;
}
</style>

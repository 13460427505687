<template>
	<div id="languageselect">
		<div @mouseenter="showDropdown()" @mouseleave="hideDropdown()">
			<b-dropdown ref="flagsDropdown" menu-class="flags-dropdown" variant="none" right toggle-class="text-decoration-none" no-caret>
				<template #button-content>
					<font-awesome-icon v-if="isLayoutLimited && !alwaysFlag" :icon="['fas', 'globe']" class="text-blue" size="lg" />
					<img v-else :src="require(`../../assets/images/flags/${userLanguage}.svg`)" alt="language flag" height="20" width="50" />
				</template>
				<b-dropdown-item class="p-1" :class="{ 'selected': lang===userLanguage }" v-for="lang of languages" :key="lang" @click="selectLanguage(lang)">
					<div class="d-flex">
						<img :src="require(`../../assets/images/flags/${lang}.svg`)" :alt="lang" height="20" width="50">
					</div>
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
export default {
	name: 'language-select',

	props: ['alwaysFlag'],

	data() {
		return {
			languages: this.$i18n.availableLanguages,
		}
	},

	methods: {

		showDropdown() {
			if (this.isLayoutLimited && !this.alwaysFlag) return;
			if (this.$refs['flagsDropdown'].$el.classList.contains('disabled')) return;
			this.$refs['flagsDropdown'].visible = true;
		},

		hideDropdown() {
			if (this.isLayoutLimited && !this.alwaysFlag) return;
			this.$refs['flagsDropdown'].visible = false;
		},

		selectLanguage(lang) {
			this.userLanguage = lang;
			this.$store.dispatch('app/changeAppLocale', this.userLanguage);
			this.$emit('changed');
		},

	},

	computed: {

		isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

		userLanguage: {
			get() { return this.$store.getters['user/getSelectedLanguage']; },
			set(val) { this.$store.commit('user/selectedLanguageSave', val); }
		},

	},

}
</script>

<style>
.flags-dropdown {
	min-width: 0 !important;
	background-color: #eee;
	overflow-y: auto;
	max-height: 80vh;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}
.flags-dropdown a.dropdown-item {
	width: fit-content;
	padding: 5px 0px;
}
.flags-dropdown .selected .dropdown-item {
	background-color: #0d6efd;
}
.flags-dropdown .dropdown-item:hover, 
.flags-dropdown .dropdown-item:focus {
	background-color: #fec801;
}
</style>

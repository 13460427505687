import fs from "fs";

export function mkdirAsync(path) {
  return new Promise((resolve, reject) => {
    fs.mkdir(path, { recursive: true }, (err) => { err ? reject(err) : resolve() })
  });
}

export function readdirAsync(path) {
  return new Promise((resolve, reject) => {
    fs.readdir(path, (err, files) => { err ? reject(err) : resolve(files) })
  });
}

export function writeFileAsync(path, data) {
  return new Promise((resolve, reject) => {
    fs.writeFile(path, JSON.stringify(data), {}, (err) => { err ? reject(err) : resolve() })
  });
}

export function readFileAsync(path, encoding = 'utf-8') {
  return new Promise((resolve, reject) => {
    fs.readFile(path, { encoding }, (err, data) => {
      if (err) { reject(err) }
      encoding === 'utf-8' ? resolve(JSON.parse(data)) : resolve(data)
    })
  });
}

export function moveFileAsync(oldPath, newPath) {
  return new Promise((resolve, reject) => {
    fs.rename(oldPath, newPath, (err) => { err ? reject(err) : resolve() })
  });
}

export function copyFileAsync(sourcePath, destinationPath) {
  return new Promise((resolve, reject) => {
    fs.copyFile(sourcePath, destinationPath, (err) => { err ? reject(err) : resolve() })
  });
}

export function deleteFileAsync(path) {
  return new Promise((resolve, reject) => {
    fs.unlink(path, (err) => { err ? reject(err) : resolve() })
  });
}

export default {
  copyFileAsync,
  deleteFileAsync,
  mkdirAsync,
  moveFileAsync,
  readdirAsync,
  readFileAsync,
  writeFileAsync
}

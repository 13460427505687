import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";
import { getLessonItems } from "../../shared/helpers/lessons";
import { version } from '../../../package.json';
import md5 from 'md5';
import moment from 'moment';

const FILE_SERVER = envConfig.FILE_SERVER;

const state = () => ({
    session: {
        authenticated: false,
        firstName: "",
        lastName: "",
        username: "student",
        email: "",
        level: "",
        // courses: [],
        languages: ['en', 'es', 'de'],
        selectedLanguage: 'en',
        token: null,
        registrationDate: '',
        expirationDate: '',
        acceptedPolicy: {
            personaldata: false,
            mediaconsent: false,
        },
        orgId: 0,
        serialNumber: '',
        organization: {},
        sessionId: null,
        progress: {
            // Keys for current lesson
            // current: ['gp1', 'pm', 'PMB', '4', '6', 'B11'],
            currentLesson: null,
            // Keys for next lesson
            // next: ['gp1', 'pm', 'PMB', '4', '7', 'B19']
            nextLesson: null
        }
    }
})

// getters
const getters = {
    getSession: state => {
        return state.session;
    },
    isAuthenticated: state => {
        return state.session.authenticated;
    },
    isExpired: state => {
        const d = new Date();
				const todayFormatted = new Date(d.getFullYear(), d.getMonth() + 1, d.getDay());
				const expirationDate = new Date(state.session.expirationDate);
				
				if (todayFormatted > expirationDate) { return true; }
				return false;
    },
    getFirstName: state => {
        return state.session.firstName;
    },
    getLastName: state => {
        return state.session.lastName;
    },
    getUsername: state => {
        return state.session.username;
    },
    getEmail: state => {
        return state.session.email;
    },
    getLevel: state => {
        return state.session.level;
    },
    getLanguages: state => {
        return  state.session.languages;
    },
    getSelectedLanguage: state => {
        return  state.session.selectedLanguage;
    },
    isAdmin: state => {
        return state.session.authenticated && state.session.level === 100;
    },
    getToken: state => {
        return state.session.token;
    },
    getRegistrationDate: state => {
        return state.session.registrationDate;
    },
    getExpirationDate: state => {
        return state.session.expirationDate;
    },
    getOrgId: state => {
    	return state.session.orgId;
    },
    getSerialNumber: state => {
    	return state.session.serialNumber;
    },
    getDownloadAvailable: state => {
        return state.session.organization.download_available;
    },
    getSessionId: state => {
        return state.session.sessionId;
    },
    hasAcceptedPolicy: (state) => (policy) => {
        if (policy == 'personaldata') {
            return state.session.acceptedPolicy.personaldata ? moment().diff(state.session.acceptedPolicy.personaldata,'days')<180 : false;
        } else {
            return !!state.session.acceptedPolicy[policy];
        }
    },
    canDownloadApp: (state, getters, rootState, rootGetters) => {
        if (getters.isAdmin) return true;
        return (!!getters.getSerialNumber) || (getters.getDownloadAvailable && rootGetters['userCourses/hasActiveCourses']);
    },
    // Get current lesson items (as array of {name, code} items)
    // Skips always the first 3 items, shows only [menu1, menu2, lesson]
    getUserCurrentLessonItems: state => getLessonItems({items: state.user.lesson.current, skip: 3}),
        // Get next lesson items (as array of {name, code} items)
    // Skips always the first 5 items, shows only [lesson]
    // TODO: Skip 3 to 5 items dynamically (depending on which keys are identical between current and next lesson)
    getUserNextLessonItems: state => getLessonItems({items: state.user.lesson.next, skip: 5})
}

// actions
const actions = {
    authenticate({ commit }, params) {
        commit("authenticatedSave", true);
        commit("firstNameSave", params.user.firstName);
        commit("lastNameSave", params.user.lastName);
        commit("usernameSave", params.user.username);
        commit("emailSave", params.user.email);
        commit("levelSave", params.user.level);
        commit("languagesSave", params.user.languages);
        commit("tokenSave", params.token);
        commit("registrationDateSave", params.user.registration);
        commit("expirationDateSave", params.user.expire);
        commit('acceptedPolicySave', {policy: 'personaldata', accepted: params.user.accepted_policy_personaldata});
        commit('acceptedPolicySave', {policy: 'mediaconsent', accepted: params.user.accepted_policy_mediaconsent});
        commit("orgIdSave", params.user.org_id);
        commit('serialNumberSave', params.user.serialNumber);
        commit('organizationSave', params.user.Organization);
        commit('sessionIdSave', params.user.sessionId);
    },
    clearSession({commit, dispatch}){
        // Remove user content checksum
        localStorage.removeItem("contentChecksum");
        // Remove user courses
        dispatch('userCourses/setUserCourses', [], { root: true })

        commit("authenticatedSave", false);
        commit("firstNameSave",        '');
        commit("lastNameSave",         '');
        commit("usernameSave",         '');
        commit("emailSave",            '');
        commit("levelSave",            -1);
        commit("languagesSave",        []);
        commit("tokenSave",          null);
        commit("registrationDateSave", '');
        commit("expirationDateSave",   '');
        commit('acceptedPolicySave', {policy: 'personaldata', accepted: false});
        commit('acceptedPolicySave', {policy: 'mediaconsent', accepted: false});
        commit("orgIdSave",             0);
        commit('serialNumberSave', '');
        commit('organizationSave', {});
        commit('sessionIdSave', null);
    },

    async login({ commit, dispatch, rootGetters }, credentials) {
      try {
        const response = await axios.post(`${FILE_SERVER}/user/login`, {
            ...credentials,
            sessionId: rootGetters['app/isOnline'] ? md5(credentials.username + credentials.password + Date.now()) : null,
        }, { validateStatus: false });
        if (response.status && response.status === 200) {
          dispatch("authenticate", { user: response.data.user, token: response.data.token });
          return Promise.resolve();
        } else if (response.status && response.status === 404) {
          return Promise.reject({ status: 404, message: 'login/user_not_found' });
        } else {
          return Promise.reject({ status: response.status, message: 'login/unexpected_error' });
        }
      } catch(e) {
         return Promise.reject(e);
      }
    },

    async forgotPassword({ commit }, { emailOrUsername, language }) {
        try {
          const response = await axios.post(`${FILE_SERVER}/user/forgot-password`, {emailOrUsername, language}, { validateStatus: false });
          if (response.status && response.status === 200) {
            return Promise.resolve();
          } else {
            return Promise.reject({ status: response?.status, message: 'forgot_password/error' });
          }
        } catch(e) {
           return Promise.reject(e);
        }
    },

    async updatePassword({ commit }, { currentPassword, newPassword }) {
    	try {
    		// Post new password
    		await axios.post(`${FILE_SERVER}/user/change-password`, { current_password: currentPassword, new_password: newPassword });
		    return Promise.resolve();
    	} catch(e) {
    	  return Promise.reject(e);
    	}
    },

    async fetchSerialNumber(){
        return await axios.post(`${FILE_SERVER}/user/get-serial-number`, {
            params: { version },
        }).then((response) => {
            if (response.data.serialNumber !== null) {
                return response.data.serialNumber;
            } else {
                console.log('ERROR retrieving serial number: unexpected data.');
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    },

    async fetchSessionId(){
        const t = Date.now();
        return await axios.get(`${FILE_SERVER}/user/get-session`, {params: {t}}).then((response) => {
            if (response.data.t != md5(t+response.data.sessionId)) {
                //wrong response, force relogin
                delete response.data.sessionId;
            }
            return response.data;
        }).catch(error => {
            if ([401,403].includes(error.response.status)) return error.response.data;
            console.log(error);
            //could not connect?
            return false;
        });
    },

    async acceptPolicy({commit}, {policy, accepted}) {
        try {
            await axios.post(`${FILE_SERVER}/user/accept-policy`, {policy, accepted});
            commit('acceptedPolicySave', {policy, accepted: (accepted ? moment().format('YYYY-MM-DD') : false)});
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    },

}

// mutations
const mutations = {
    authenticatedSave(state, authenticated) {
        state.session.authenticated = authenticated;
    },
    firstNameSave(state, firstName) {
        state.session.firstName = firstName;
    },
    lastNameSave(state, lastName) {
        state.session.lastName = lastName;
    },
    emailSave(state, email) {
        state.session.email = email;
    },
    usernameSave(state, username) {
        state.session.username = username;
    },
    levelSave(state, level) {
        state.session.level = level;
    },
    languagesSave(state, languages) {
        if (languages == null) return;

        state.session.languages = languages;
    },
    selectedLanguageSave(state, language) {
        state.session.selectedLanguage = language;
    },
    // coursesSave(state, courses) {
    //     state.session.courses = courses;
    // },
    tokenSave(state, token) {
        state.session.token = token;
    },
    registrationDateSave(state, registrationDate) {
        state.session.registrationDate = registrationDate;
    },
    expirationDateSave(state, expirationDate) {
        state.session.expirationDate = expirationDate;
    },
    orgIdSave(state, orgId) {
    	state.session.orgId = orgId;
    },
    serialNumberSave(state, sn) {
    	state.session.serialNumber = sn;
    },
    organizationSave(state, org) {
    	state.session.organization = org;
    },
    sessionIdSave(state, sessionId) {
        state.session.sessionId = sessionId;
    },
    acceptedPolicySave(state, {policy, accepted}) {
        console.log('acceptedPolicySave', policy, accepted);
        state.session.acceptedPolicy = state.session.acceptedPolicy || {};
        state.session.acceptedPolicy[policy] = accepted;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


import envConfig from '../../shared/configs/config.local.js'
const files = require.context('.', false, /\.js$/)

const modules = {}

files.keys().forEach(key => {
  // Skip self
  if (key === './index.js') return;

  // Set cache module based on env
  if (key === './cache.js' && envConfig.buildType === 'online') { return; }
  if (key === './cache-online.js' && envConfig.buildType !== 'online') { return; }

  const moduleKey = ['./cache.js', './cache-online.js'].includes(key) ? 'cache' : key.replace(/(\.\/|\.js)/g, '');
  modules[moduleKey] = files(key).default
})

export default modules

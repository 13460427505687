import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const actions = {
  async fetchGlossaryImage({ getters, commit }, name) {
    try {
      // Fetch
      const params = { title: name };
      const response = await axios.get(`${FILE_SERVER}/glossary/fetchImage`, { params });
      const imageBase64 = response.data;

      return Promise.resolve(imageBase64);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default { namespaced: true, actions }

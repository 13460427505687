import Vue from 'vue'
import electron from 'electron'
import fs from 'fs'
import path from 'path'
import crypto from 'crypto'
import yaml from 'js-yaml'
import {Decoder} from '../../shared/helpers/encoding.js'
import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";
import fileSystem from "../../shared/utils/fileSystem";
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import i18n from '../../i18n';

let toBuffer = require('blob-to-buffer')
// File server URL
const FILE_SERVER = envConfig.FILE_SERVER;

let PRODUCTS_CONTENT = {};

// File methods (from generic to specific)
const File = {
  // Get %USERDATA% folder
  userFolder: (electron.app || electron.remote.app).getPath('userData'),

  // Get app folder in %USERDATA%
  abs: (relPath) => path.join(File.userFolder, 'tlsRepo', relPath),

  // Select a random name
  randomName: () => crypto.randomBytes(20).toString('hex').slice(0, 40) + '.cache',

  exists: (absPath) => fs.existsSync(absPath),

  // Make sure that a folder exists
  ensureFolder: async (absPath) => {
    if (fs.existsSync(absPath) === false) {
      fs.mkdirSync(absPath)
    }
  },

  // Save a blob to a local file
  saveBlob: async (absPath, data) => {
    const p = new Promise(function (resolve, reject) {
      toBuffer(data, (err, buffer) => {
        if (err) {
          reject(err)
        } else {
          resolve(buffer)
        }
      })
    })

    const buf = await p

    // TODO: Make this async (filesystem util stringifies only)
    fs.writeFileSync(absPath, buf)
  },

  // Text file methods (read a string from a file)
  text: {
    load: (absPath) => {
      let result
      try {
        result = fs.readFileSync(absPath, 'utf8')
      } catch (e) {
        // Return an empty string
        result = ''
      }
      return result
    }
  },

  // Delete safely a file
  delete: async (path) => { try { return await fileSystem.deleteFileAsync(path) } catch (e) { return Promise.reject(e) } },

  // YAML methods (read/write an obj to a file)
  yaml: {
    load: (absPath) => yaml.load(File.text.load(absPath)) || {},
    save: (absPath, obj) => fs.writeFileSync(absPath, yaml.dump(obj), 'utf8')
  },

  // Faqs file (contains the content structure loaded from server, describing menus, lessons etc)
  faqs: {
    path: () => File.abs('index_fq.bin'),
    load: async () => { try { return await fileSystem.readFileAsync(File.faqs.path()) } catch (e) { return Promise.resolve({}) } },
    save: async (obj) => { try { await fileSystem.writeFileAsync(File.faqs.path(), obj) } catch (e) { return Promise.reject(e) } }
  },

  // Glossary file (contains the content structure loaded from server, describing menus, lessons etc)
  glossary: {
    path: () => File.abs('index_g.bin'),
    load: async () => { try { return await fileSystem.readFileAsync(File.glossary.path()) } catch (e) { return Promise.resolve({}) } },
    save: async (obj) => { try { await fileSystem.writeFileAsync(File.glossary.path(), obj) } catch (e) { return Promise.reject(e) } }
  },

  // Instructions file (contains the 'how to study my course' instructions structure loaded from server)
  instructions: {
    path: () => File.abs('index_i.bin'),
    load: async () => { try { return await fileSystem.readFileAsync(File.instructions.path()) } catch (e) { return Promise.resolve({}) } },
    save: async (obj) => { try { await fileSystem.writeFileAsync(File.instructions.path(), obj) } catch (e) { return Promise.reject(e) } }
  },

  // Content file (contains the content structure loaded from server, describing menus, lessons etc)
  content: {
    path: () => File.abs('index_c.bin'),
    load: async () => { try { return await fileSystem.readFileAsync(File.content.path()) } catch (e) { return Promise.resolve({}) } },
    save: async (obj) => { try { await fileSystem.writeFileAsync(File.content.path(), obj) } catch (e) { return Promise.reject(e) } }
  },

  // Filetree file (contains info about files, versions etc)
  filetree: {
    path: () => File.abs('index_f.bin'),
    load: async () => { try { return await fileSystem.readFileAsync(File.filetree.path()) } catch (e) { return Promise.resolve({}) } },
    save: async (obj) => { try { await fileSystem.writeFileAsync(File.filetree.path(), obj) } catch (e) { return Promise.reject(e) } }
  }
}

// Select language
const languages = i18n.availableLanguages;

// Function to return the real path of a file/language instance
const realPath = ({files, file, lang}) => {
  try {
    if (!files[file]) { throw new Error('file could not be found'); }
    if (!files[file].language[lang]) {
      // console.log(`Returning english version of ${file}`);
      return File.abs(files[file].language.en.filename);
    }

    return File.abs(files[file].language[lang].filename)
  } catch (e) {
    throw new Error(`${file} - ${e}`);
  }
}

// Function to generate a new random name (for saving locally a file)
const newRandomName = () => crypto.randomBytes(20).toString('hex').slice(0, 40) + '.cache'

// ---------------------------------------------------------------------------------------

// Buffer with the current app state and local cache structures
const state = {

  // Content loaded from server and cached locally
  content: {
    products: {}
  },

  // User current status
  current: {
    productKey: null,
    course: null,
    menu1Index: null,
    menu2Index: null,
    lessonKey: null
  },

  // User previous status
  previous: {
    lesson: { key: null, isMainLesson: false, mainLessonPageNum: null, hasAssignment: false }
  },

  // // User next status
  // next: {
  //   productKey: null,
  //   course: null,
  //   menu1Index: null,
  //   menu2Index: null,
  //   lessonKey: null
  // },

  // Latest menu2 cursor of each menu1 item
  // latest.menu2[menu1Index] is the latest menu2 index of every menu1 item
  // We keep it here, so when user changes menu1 item, we store menu2 index from here instead of having to reset it every time
  latest: {
    menu2: {},
  },

  // App status
  status: {
    // Content details
    content: {
      // True = Content is saved locally. False = Content has not been saved yet locally (probably first time running)
      local: false,
      // True = Content was loaded and updated -if possible- from server
      initialized: false,
      // True = contacted fileserver. False = Network error
      serverContacted: false
    }
  },

  missingFilesDownload: {
    pendingDownload: false,
    total: 0,
    downloaded: 0
  },

  // TODO: Check below

  // App menu
  menu: {
    // All app menu items, as they are loaded from fileserver
    items: [],

    // Definitions for all menu levels, as they are loaded from fileserver
    levels: [],

    // Current menu keys. Array with level keys, one for each level, starting from level 0. Array length indicates the levels chosen
    currentKeys: [],

    // Place here a copy of currentKeys whenever selectCurrent is called (entering or leaving a menu level)
    lastCurrentKeys: []
  },

  /*
    File tree (local)
    Load/save in local cache
    Update info from server (add/remove files)
    Keeps definitions only and info of real paths to local cache
    To sync with server for a given language:
      1. Set to all files (of this language) the property synced = false
      2. Read files (of this language) from server to a temp structure (incoming)
      3. For each incoming file:
        - Set synced = true
        - Set present = false if file does not exist or version is wrong, = true if file exists and version is right
      4. Delete all entries and files (of this language) where synced=false, because server did not mention them
      5. Delete all files (of this language) where synced=true and present=false, because there is a new version to download
      6. Get all files (of this language) where synced=true and present=false
      7. Save local files info

  // Object with one property for each file, where property name = the filepath and property value is an object with properties:
  */
  files: {
    // filename:
    /* {
      language: {
        <code>: {
          filename: string // The actual filename in disk, where the file is stored. May be a hex random string
          version: string // The current version of the file, as lastly reported from server. Existing local file may contain older version or no file at all
          present: boolean // false = Local file needs to be loaded from the server. true = Local file has been loaded from the server
          synced: boolean // false = Version of local file unknown or mismatch with the server version, true = Local version is the correct one
        }
    } */
  }
}

// Get current item of a menu, using a path array of keys
//    keys: An array of keys, one for each level, starting from root level of parent
//    parent: The parent node. Levels count 0,1,2,... from this node and downwards
//    level: The level we want to get the item. If level is > keys, then return null
const getCurrentItem = (keys, parent, level) => {
  try {
    return keys.length > level && parent && parent.items ? parent.items.find(r => r.key === keys[level]) : null
  } catch (e) {
    console.log('CACHE ERROR:')
    console.log(e)
    return null
  }
}

// Get selectable children of an item
const getSelectableChildren = (keys, parent, level) => {
  return (keys.length > level) && parent ? parent.items : null
  // let item =   keys[level] getCurrentItem(keys, parent, level)
  // return item && parent.items ? parent.items : null
}

const getters = {

  // Get faq info
  getFaqs: async () => {
    try {
      const content = await File.faqs.load();
      return Promise.resolve(content ? content.faqs : {});
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // Get glossary
  getGlossary: async () => {
    try {
      const content = await File.glossary.load();
      return Promise.resolve(content ? content.glossary : {});
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // Get Instructions
  getInstructions: async () => {
    try {
      const content = await File.instructions.load();
      return Promise.resolve(content ? content.courseInstructions : {});
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // Get menu1 items
  // Current product must be selected
  // => [{index, key, title, descr}]
  getMenu1Items: (state, rootGetters) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const prd = productSkeleton || {}
    const menu1 = (prd.menu || {}).menu1 || []
    return menu1.map((m, index) => {
      const key = m.props.key;
      let translatedMenuProps = m.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
      if (translatedMenuProps) {
        const { title, description } = translatedMenuProps;
        return { index, key, title, descr: description }
      }
      translatedMenuProps = m.props;
      const { title, descr } = translatedMenuProps;
      return { index, key, title, descr }
    })
  },

  // Get menu2 items
  // Current product,menu1 must be selected
  // => [{index, title}]
  getMenu2Items: (state, rootGetters) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const prd = productSkeleton || {}
    const menu1 = (prd.menu || {}).menu1 || []
    const menu2 = (menu1[state.current.menu1Index] || {}).menu2 || []
    return menu2.map((m, index) => {
      const { w, h } = m.props;
      let translatedMenuProps = m.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
      if (!translatedMenuProps) { translatedMenuProps = m.props; }
      const { title } = translatedMenuProps;
      return { index, title, w, h }
    })
  },

  // Get menu lessons items
  // Current product, menu1, menu2 must be selected
  getMenuLessons: (state, rootGetters) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const prd = productSkeleton || {}
    const crs = (prd.courses || {})[state.current.course] || {}
    const visibles = crs.visibles || []
    const lessons = prd.lessons || {}
    const menus1 = (prd.menu || {}).menu1 || []
    const menus2 = (menus1[state.current.menu1Index] || {}).menu2 || []
    const menu2 = menus2[state.current.menu2Index] || {}
    const lessonKeys = (menu2.lessons || []) // .filter(key => visibles.includes(key)) to remove non visible lessons
    const w = menu2.props ? menu2.props.w || 90 : 90
    const h = menu2.props ? menu2.props.h || 80 : 80
    return lessonKeys.filter(key => lessons[key])
      .map(key => {
        const lesson = lessons[key] || {}
        const props = lesson.props || lesson
        return {
          key,
          enabled: visibles.includes(key),
          id: props.dbid,
          title: props.title[rootGetters["user/getSelectedLanguage"]] || props.title.en,
          image: props.image,
          w,
          h
        }
      })
  },

  getMainLessonDetails: (state, getters) => (lessonId) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey];
    const lessons = productSkeleton?.lessons;
    if (!lessons) { return { menu1Index: null, menu2: null, code: '' , hasAssignment: false }; }

    const mainLessonKey = Object.keys(lessons).find(key => {
      const lessonContent = lessons[key];
      return key.includes('_ml') && lessonContent.props.html.languages.en.pages.some(p => p.subLessons.find(l => parseInt(l.subLessonId) === parseInt(lessonId)))
          ? true
          : false;
    });
    if (!mainLessonKey) { return { menu1Index: null, menu2: null, code: '' , hasAssignment: false }; }
    const mainLessonMenuEntries = getters.getLessonMenuEntries(mainLessonKey);
    const mainLessonPageNum = lessons[mainLessonKey].props.html.languages.en.pages.findIndex(p => p.subLessons.find(l => parseInt(l.subLessonId) === parseInt(lessonId)));

    return {
      menu1Index: mainLessonMenuEntries.menu1.index,
      menu2Index: mainLessonMenuEntries.menu2.index,
      code: mainLessonKey,
      dbid: lessons[mainLessonKey].props.dbid,
      hasAssignment: lessons[mainLessonKey].props.hasAssignment || false,
      mainLessonPageNum,
    };
  },

  // Get current (selected) keys and indices
  // => { product, course, menu1Index, menu2Index, lesson }
  getSelected: (state) => {
    const result = { ...state.current }

    // .product
    const product = PRODUCTS_CONTENT.products?.[state.current.productKey] || {}
    result.product = state.current.productKey ? product.props : {}

    // .lesson
    const lesson = state.current.lessonKey ? product.lessons[state.current.lessonKey] : {}
    result.lesson = state.current.lessonKey ? lesson.props || lesson : {}

    return result
  },

  // In case this is the first time the user studies a lesson of the currently selected course, get first lesson to study id
  getFirstLessonToStudy: (state) => {
  	const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    return productSkeleton.courses[state.current.course].lessonsOrder[0].id;
  },

  // Get previously viewed lesson
  getPreviouslyViewedLesson: (state) => {
    return state.previous.lesson;
  },

  // Get current lesson key => { key, title, menu1, menu2 }
  getCurrentlyViewingLesson: (state, rootGetters) => {
    let menu1 = {};
    let menu2 = {};
    let code = '';
    let title = '';

    if (state.current.productKey && state.current.course) {
      const selectedProduct = rootGetters["app/isOnline"]
      	? rootGetters["products/getCurrentProductSkeleton"]
      	: PRODUCTS_CONTENT.products[state.current.productKey];
      const selectedCourseSections = selectedProduct.menu.menu1;

      const currentLesson = selectedProduct.lessons[state.current.lessonKey];
      code = state.current.lessonKey;
      title = (currentLesson.props || currentLesson).title[rootGetters["user/getSelectedLanguage"]] || (currentLesson.props || currentLesson).title.en

      if (state.current.menu1Index >= 0) {
        let menu = selectedCourseSections[state.current.menu1Index];
        let translation = menu.props.translations.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        let title = translation ? translation.title : menu.props.title;
        menu1 = { index: state.current.menu1Index, title };

        menu = selectedCourseSections[state.current.menu1Index].menu2[state.current.menu2Index]
        translation = menu.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        title = translation ? translation.title : menu.props.title;
        menu2 = { index: state.current.menu2Index, title };
      }
    }
    return { menu1, menu2, code, title };
  },

  // Get next lesson key based on current course's lesson order => { key, title, menu1, menu2 }
  getNextLesson: (state, getters) => (usePreviouslyViewedLesson) => {
    if (!state.current.productKey || !state.current.course) { return { menu1: {}, menu2: {}, code: '', title: '' }; }

    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]

    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder.map(l => getters.getLessonCode(l.id));


    let currentLessonOrder = -1
    if (usePreviouslyViewedLesson) {
      currentLessonOrder = courseLessonsOrdered.findIndex(l => l === state.previous.lesson.key);
    } else {
      const previousLessonOrder = courseLessonsOrdered.findIndex(l => l === state.previous.lesson.key);
      currentLessonOrder = courseLessonsOrdered.findIndex(l => l === state.current.lessonKey)
      currentLessonOrder = previousLessonOrder > currentLessonOrder
      	? previousLessonOrder + 1
      	: currentLessonOrder
    }

    if (currentLessonOrder > -1 && currentLessonOrder < courseLessonsOrdered.length - 1) {
      const nextLessonCode = courseLessonsOrdered[currentLessonOrder + 1];

      return getters.getLessonMenuEntries(nextLessonCode);
    }

  },

  // Get lesson key for given lesson id => key
  getLessonCode: (state) => (lessonId) => {
    lessonId = +lessonId;
    if (Number.isNaN(lessonId)) { return undefined; }

    const product = PRODUCTS_CONTENT.products[state.current.productKey];
    const lessonCodes = Object.keys(product.lessons).filter(k => (product.lessons[k].props || product.lessons[k]).dbid === lessonId);

    // There may be more than one keys for the same lesson in case of an MLFP (mtitle, mtitle_ml). In this case, return mtitle_ml if present.
    return lessonCodes.length > 1 && lessonCodes.find(code => code.includes('_ml'))
      ? lessonCodes.find(code => code.includes('_ml'))
      : lessonCodes[0];
  },

  getLessonId: (state) => (lessonCode) => {
    const lesson = PRODUCTS_CONTENT.products[state.current.productKey].lessons[lessonCode];
    return (lesson.props || lesson).dbid
  },

  // Returns true or false depending on whether the given lesson has an assignment instructions image
  hasAssignmentInstructionsImage: (state) => (lessonCode) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey];
    return productSkeleton.lessons[lessonCode].hasAssignmentImage;
  },

  // Get menu entries for given lesson code => { key, title, menu1, menu2 }
  getLessonMenuEntries: (state, rootGetters) => (lessonCode) => {
    if (!lessonCode) { return { menu1: {}, menu2: {}, code: '' , title: '' }; }

    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const menu1 = productSkeleton.menu.menu1;

    const menuEntries = { menu1: -1, menu2: -1 }
    for (let i = 0; i < menu1.length; i++) {
      const menu2 = menu1[i].menu2;
      if (menu2) {
        for (let j = 0; j < menu2.length; j++) {
          if (menu2[j].lessons && menu2[j].lessons.findIndex(lesson => [lessonCode, `${lessonCode}_ml`].includes(lesson)) > -1) {
            const translation = menu2[j].props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
            const title = translation ? translation.title : menu2[j].props.title;
            menuEntries.menu2 = { index: j, title };
            break;
          }
        }
      }
      if (menuEntries.menu2 !== -1) {
      	const translation = menu1[i].props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
        const title = translation ? translation.title : menu1[i].props.title;
        menuEntries.menu1 = { index: i, title };
        break;
      }
    }

    const lessonProps = productSkeleton.lessons[lessonCode]
      ? productSkeleton.lessons[lessonCode].props || productSkeleton.lessons[lessonCode]
      : productSkeleton.lessons[`${lessonCode}_ml`].props || productSkeleton.lessons[`${lessonCode}_ml`];
    const title = lessonProps.title[rootGetters["user/getSelectedLanguage"]] || lessonProps.title.en;
    const code = productSkeleton.lessons[lessonCode] ? lessonCode : `${lessonCode}_ml`;
    return { menu1: menuEntries.menu1, menu2: menuEntries.menu2, code , title };
  },

  // Get package id for given lesson id => packageId
  getPackageId: (state, getters) => (lessonId) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    let lessonOrderEntry = productSkeleton.courses[state.current.course].lessonsOrder?.find(e => e.id === lessonId)

    // Use main lesson package id in case lesson Id is the currently selected lesson and previous lesson is an MLFP
    const useMainLessonPackage = state.current.lessonKey === getters.getLessonCode(lessonId) && state.previous.lesson.isMainLesson;
    if (!lessonOrderEntry && useMainLessonPackage) {
      const mainLessonId = productSkeleton.lessons[state.previous.lesson.key].dbid;
      lessonOrderEntry = productSkeleton.courses[state.current.course].lessonsOrder?.find(e => e.id === mainLessonId);
    }

    // TODO: What package_id should we return by default
    return lessonOrderEntry ? lessonOrderEntry.package_id : 1;
  },

  // Get package pdf name for given lesson code => pdfName
  getPackagePdf: (state) => (lessonCode) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey];
    const lessonId = productSkeleton.lessons[lessonCode].props.dbid
    const lessonOrderEntry = productSkeleton.courses[state.current.course].lessonsOrder?.find(e => e.id === lessonId)
    if (!lessonOrderEntry) { return ''; }

    const packageId = lessonOrderEntry.package_id;
    const lessonPackage = Object.keys(productSkeleton.lpackages)?.find(p => productSkeleton.lpackages[p].props.packageId === packageId);
    return lessonPackage ? productSkeleton.lpackages[lessonPackage].props.pdf : '';
  },

  getCoursePackages: (state, getters) => {
    const packages = []

    const currentProduct = PRODUCTS_CONTENT.products[state.current.productKey]
    const selectedCoursePackages = currentProduct.courses[state.current.course].lpackages;
    const selectedCoursePackagesOrder = getters.getCoursePackagesOrder;
    selectedCoursePackages.forEach(lp => {
      const packageLessons = currentProduct.courses[state.current.course].lessonsOrder
        .filter(l => l.package_id === currentProduct.lpackages[lp].props.packageId)
        .map(l => {
          const lessonCode = getters.getLessonCode(l.id);
          return getters.getLessonMenuEntries(lessonCode);
        })
      const packageOrder = selectedCoursePackagesOrder.findIndex(pkgId => pkgId === parseInt(currentProduct.lpackages[lp].props.packageId));
      packages.push({ ...currentProduct.lpackages[lp].props, lessons: packageLessons, order: packageOrder })
    })
    return packages;
  },

  getCoursePackagesOrder: (state) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey];
    const packagesOrder = productSkeleton.courses[state.current.course]?.lpackagesOrder;
    return packagesOrder ? packagesOrder : [];
  },

  isAfter: (state, getters) => (lessonKey1, lessonKey2) => {
    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder.map(l => getters.getLessonCode(l.id));

    const lessonOrder1 = courseLessonsOrdered.findIndex(l => l === lessonKey1);
    const lessonOrder2 = courseLessonsOrdered.findIndex(l => l === lessonKey2);

    return lessonOrder1 > lessonOrder2;
  },

  isAllowedToView: (state, rootGetters) => (lessonId) => {
    if (rootGetters["user/isAdmin"]) { return true; }

    const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey]
    const courseLessonsOrdered = productSkeleton.courses[state.current.course].lessonsOrder;

    const nextToStudy = rootGetters["progress/getNextToStudy"];

    if (!nextToStudy.id || !nextToStudy.menu.code) { return true; }

    const nextToStudyIndex = courseLessonsOrdered.findIndex(l => l.id === nextToStudy.id);
    const lessonIndex = courseLessonsOrdered.findIndex(l => l.id === lessonId);

    return nextToStudyIndex > -1 && lessonIndex > -1 && lessonIndex <= nextToStudyIndex;
  },

  // Check if content+files were loaded and initialized, so that the user may begin using the app
  isInitialized: (state) => !!state.status.content.initialized,

  // Check if fileserver was contacted in the last attempt
  isServerContacted: (state) => !!state.status.content.serverContacted,

  // Check if some content has been saved locally (in local file cache) at least once
  isContentSaved: (state) => state.status.content.local,

  // Get the real absolute path of a resource file
  getRealPath: (state) => ({file, lang}) => realPath({files: state.files, file, lang}),

  // TODO: Check below

  // Get info about local files ({$file:{language:{$code:{filename, version, present, synced}}}})
  getLocalFiles: (state) => state.files,

  // Get all menu current keys
  getMenuKeys: (state) => state.menu.currentKeys,

  // Get an array of the descriptions of the current lesson
  getCurrentLessonDescrtiption: () => {},

  // Get lesson key of previous (last) selection
  // TODO: Get full position instead of lesson only
  // => String
  getLastLesson: (state) => state.menu.lastCurrentKeys[5] || null,

  // Get info about the menu levels
  getMenuLevels: (state) => state.menu.levels,

  // Get all menu items
  getMenuItems: (state) => state.menu.items,

  // Get array of not present files
  getNotPresentFiles: (state) => () => {
    const npFiles = [];
    // For each file
    for (const [fileName, fileData] of Object.entries(state.files)) {
      // For each language
      Object.keys(fileData.language).forEach(language => {
        if (!fileData.language[language].present) { npFiles.push({fileName, language}) }
      })
    }
    return npFiles;
  },

  // Get record of current group, product, ....
  getCurrentGroup: (state) => getCurrentItem(state.menu.currentKeys, state.menu, 0),
  getCurrentProduct: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentGroup, 1),
  getCurrentCourse: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentProduct, 2),
  getCurrentMenu1: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentCourse, 3),
  getCurrentMenu2: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentMenu1, 4),
  getCurrentLesson: (state, getters) => getCurrentItem(state.menu.currentKeys, getters.getCurrentMenu2, 5),

  // Get Object with selectable items for level 0, 1, ......
  getSelectableGroups: (state) => state.menu.items.length ? state.menu.items : null,
  getSelectableProducts: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentGroup, 0),
  getSelectableCourses: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentProduct, 1),
  getSelectableMenus1: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentCourse, 2),
  getSelectableMenus2: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentMenu1, 3),
  getSelectableLessons: (state, getters) => getSelectableChildren(state.menu.currentKeys, getters.getCurrentMenu2, 4),

  getFileName: (state) => ({file, lang}) => {
    return realPath({ files: state.files, file, lang })
  },

  // Get the contents of an image given its pathname, as base-64 data for embedding into HTML
  getImageData: (state) => ({file, lang}) => {
    let name = realPath({ files: state.files, file, lang })
    let data = fs.readFileSync(name)
    let data2 = Decoder('buffer')(data)
    return 'data:image/png;base64,' + data2.toString('base64')
  },

  // Missing files
  isMissingFilesDownloadPending: (state) => state.missingFilesDownload.pendingDownload,
  getMissingFilesDownloadState: (state) => state.missingFilesDownload

}

const mutations = {

  // Select a product AND course
  selectProductCourse (state, {productKey, courseKey}) {
    state.current.productKey = productKey ? productKey.toUpperCase() : productKey;
    state.current.course = courseKey;

    // Update local storage entry
    let savedState = localStorage.getItem("contentState");
    if (savedState) {
      savedState = JSON.parse(savedState)
      savedState.productKey = productKey;
      savedState.courseKey = courseKey;
    } else {
      const previous = { key: null, isMainLesson: false, mainLessonPageNum: null, hasAssignment: false };
      savedState = { productKey, courseKey, lessonKey: null, menu1Index: 1, menu2Index: 1, previous };
    }
    localStorage.setItem("contentState", JSON.stringify(savedState));
  },

  // Select a menu item
  selectMenu (state, items) {
    const changingMenu1 = items.hasOwnProperty('menu1Index')
    const changingMenu2 = items.hasOwnProperty('menu2Index')
    const changingLesson = items.hasOwnProperty('lessonKey')

    // Save menu2 of the existing menu1
    if (changingMenu1) { Vue.set(state.latest, state.current.menu1Index, state.current.menu2Index) }

    // Restore menu2 -if not given- of the new menu1
    if (changingMenu1 && !changingMenu2) {
      state.current.menu2Index = state.latest.hasOwnProperty(state.current.menu1Index) ? state.latest[state.current.menu1Index] : null
    }

    // Set previous lesson state prop
    if (changingLesson) {
      state.previous.lesson.key = state.current.lessonKey;
      const productSkeleton = PRODUCTS_CONTENT.products[state.current.productKey];

      if (productSkeleton) {
        const currentLesson = productSkeleton.lessons[state.current.lessonKey]

        state.previous.lesson.hasAssignment = currentLesson && (currentLesson.props || currentLesson).hasAssignment
      	  ? (currentLesson.props || currentLesson).hasAssignment
      	  : false;
        state.previous.lesson.isMainLesson = currentLesson
      	  && (currentLesson.props || currentLesson).type === 'html'
      	  && (currentLesson.props || currentLesson).key.includes('_ml');
      }
    }

    // Set current state prop
    for (const item of ['menu1Index', 'menu2Index', 'lessonKey']) {
      if (items.hasOwnProperty(item)) { state.current[item] = items[item]; }
    }

    // Update local storage entry
    let savedState = localStorage.getItem("contentState");
    savedState = JSON.parse(savedState);
    if (!savedState) {
      localStorage.setItem("contentState", JSON.stringify({}));
      return;
    }

    for (const item of ['menu1Index', 'menu2Index', 'lessonKey']) {
      if (items.hasOwnProperty(item)) { savedState[item] = state.current[item]; }
    }
    if (changingLesson) {
      savedState.previous = {
        key: state.previous.lesson.key,
        isMainLesson: state.previous.lesson.isMainLesson,
        mainLessonPageNum: state.previous.lesson.mainLessonPageNum,
        hasAssignment: state.previous.lesson.hasAssignment
      };
    }
    localStorage.setItem("contentState", JSON.stringify(savedState));
  },

  // Set previously viewed lesson props
  setPreviousLessonProps (state, previous) { state.previous.lesson = previous; },

  // Set previously viewed 'mainLessonPageNum' props
  setPreviousLessonPageNumProp (state, pageNum) { state.previous.lesson.mainLessonPageNum = pageNum; },

  // Set status of content initialized
  setInitialized (state, isInitialized) { state.status.content.initialized = !!isInitialized; },

  setServerContacted (state, value) { state.status.content.serverContacted = !!value; },

  // Set status of content saved
  setContentSaved (state, isSaved) { state.status.content.saved = !!isSaved },

  // TODO: Check below

  // Set files
  setFiles (state, files) {
    state.files = files
  },

  setUserContent (state, content) { PRODUCTS_CONTENT = content; },

  // Set user menu from external resource
  setUserMenu (state, {menu}) {
    let {items, levels} = menu
    state.menu.items = items
    state.menu.levels = levels
  },

  // Set current position to one or more levels in menu
  selectCurrent (state, options) {
    let {group, product, course, menu1, menu2, lesson} = options
    let r = [ ...state.menu.currentKeys ]
    if (group) {
      r = [group]
    }
    if (product && r.length > 0) {
      r = [ ...r.slice(0, 1), product ]
    }
    if (course && r.length > 1) {
      r = [ ...r.slice(0, 2), course ]
    }
    if (menu1 && r.length > 2) {
      r = [ ...r.slice(0, 3), menu1 ]
    }
    if (menu2 && r.length > 3) {
      r = [ ...r.slice(0, 4), menu2 ]
    }
    if (lesson && r.length > 4) {
      r = [ ...r.slice(0, 5), lesson ]
    }
    // Save previous (last) selection here
    state.menu.lastCurrentKeys = [ ...state.menu.currentKeys ]
    // Set new selection
    state.menu.currentKeys = r
  },

  // Mark some files (for a given language) as present
  // Files is an array of filenames
  // language is the language code
  markFilesPresent (state, files) {
    (files || []).forEach(f => {
      if (_has(state, `files[${f.fileName}].language[${f.language}]`)) { state.files[f.fileName].language[f.language].present = true; }
    })
  },

  // Missing files
  resetMissingFilesDownloadState (state) {
    state.missingFilesDownload = { ...state.missingFilesDownload, pendingDownload: false, total: 0, downloaded: 0 }
  },
  setMissingFilesDownloadState (state, { pendingDownload, total, downloaded }) {
    state.missingFilesDownload = { ...state.missingFilesDownload, pendingDownload, total, downloaded }
  },
  updateMissingFilesDownloaded (state, downloaded) {
    state.missingFilesDownload = { ...state.missingFilesDownload, downloaded: Number.isNaN(+downloaded) ? 0 : +downloaded }
  }
}

const actions = {

  // Restore current state property from localStorage
	restoreCurrentProductCourseStateProps({ commit }) {
		let savedState = localStorage.getItem("contentState");
	    if (savedState) {
	      savedState = JSON.parse(savedState);
	      commit('selectProductCourse', { productKey: savedState.productKey, courseKey: savedState.courseKey });
	  }
	},

  // Restore previous state property from localStorage
  restorePreviousStateProp({ commit }) {
    let savedState = localStorage.getItem("contentState");
    if (savedState) {
      savedState = JSON.parse(savedState);
      commit('setPreviousLessonProps', savedState.previous);
    }
  },

  // Mark app as initialized (user may start using the app)
  set_initialized ({ commit }, value) { commit('setInitialized', value); },

  // ======================================
  // ONLINE
  // ======================================

  async fetchFaqs ({ rootState, rootGetters }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/faqs`);
      const content = response.data;

      // If in online build, return faqs entries
      if (rootGetters["app/isOnline"]) { return Promise.resolve(content.faqs); }

      // Save
      await File.ensureFolder(File.abs('/'));
      await File.faqs.save(content);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchGlossary ({ rootState, rootGetters }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/glossary`);
      const content = response.data;

      // If in online build, return glossary entries
      if (rootGetters["app/isOnline"]) { return Promise.resolve(content.glossary); }

      // Save
      await File.ensureFolder(File.abs('/'));
      await File.glossary.save(content);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchInstructions ({ rootState, rootGetters }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/menu/instructions`);
      const content = response.data;

      // If in online build, return instructions entries
      if (rootGetters["app/isOnline"]) { return Promise.resolve(content.courseInstructions); }

      // Save
      await File.ensureFolder(File.abs('/'));
      await File.instructions.save(content);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchUserContent ({ commit }) {
    try {
      // Retrieve saved content checksum
      const oldChecksum = localStorage.getItem("contentChecksum");
      let response = await axios.get(`${FILE_SERVER}/menu/checksum`);
      const currentChecksum = response.data;
      const checksum = { local: oldChecksum, server: currentChecksum };

      // Check if new content is available
      if (oldChecksum === currentChecksum) { commit('setServerContacted', true); return Promise.resolve(checksum); }

      // Save current content checksum
      localStorage.setItem("contentChecksum", currentChecksum);

      // Fetch
      response = await axios.get(`${FILE_SERVER}/menu/usercontent`);
      const content = response.data;

      // Save
      await File.ensureFolder(File.abs('/'));
      await File.content.save(content);
      commit('setServerContacted', true);

      commit('setUserContent', content);
      return Promise.resolve(checksum);
    } catch (e) {
      commit('setServerContacted', false);
      return Promise.resolve(e);
    }
  },

  // Fetch the latest file tree from the server for this user
  // The file tree contains information about all content files used in lessons and menus
  // See state.files for more details about the file tree structure
  // When getting the file tree, each file is compared (existence, version) with the existing local copy
  // If a file needs to be fetched (because it does not exist locally or it is newer), it is marked with present = false
  // Not present files will be fetched in a separate action
  async fetchFileTree ({ commit }) {

    try {
      // Load local files
      let localFileTree = await File.filetree.load();
      commit('setFiles', localFileTree);

      // Fetch files
      const requests = languages.map(e => axios.get(`${FILE_SERVER}/files/treelist?metas=1&language=${e}`));
      const results = await Promise.all(requests);

      const tlsRepoFileList = await fileSystem.readdirAsync(File.abs(''));

      for (let i = 0; i < results.length; i++) {
        const result = results[i].data;
        const syncResults = syncFileTree(_cloneDeep(localFileTree), result.metas, tlsRepoFileList, languages[i]);
        localFileTree = syncResults.syncedFileTree;

        // Delete expired files
        if (syncResults.fileTreeChanged) {
          let failedToDelete = 0;
          // Delete expired files
          for (const f of syncResults.filesToDelete) {
            try {
              await File.delete(File.abs(f));
            } catch (e) {
              failedToDelete++;
            }
          }
          if (failedToDelete > 0) { console.log(`Failed to delete ${failedToDelete} files marked for deleted`); }
        }
      }
      commit('setFiles', localFileTree);
      // Save the new file tree
      await File.filetree.save(localFileTree);

      return Promise.resolve();
    } catch (e) {
      console.log(e)
      return Promise.reject(e);
    }
  },

  // Get the user menu from the fileserver
  // The user menu contains all the menus structure for this user
  async get_user_menu ({ commit, getters }) {
    await axios.get(`${FILE_SERVER}/menu/usermenu`).then(function (response) {
      if( response.status === 200){
        let data = response.data
        commit('setUserMenu', data)
      } else {
        // TODO handle
      }
    }, (error) => {
      // TODO handle
      console.log(error);
    })


    // TODO:
    // Save the user menu

    // If there are files not present, will ask to download. Else, goto specific menu
    if (!getters.getNotPresentFiles().length) {
      // Select the root level
      commit('selectCurrent', {group: 'gp1', product: 'pm', course: 'PMB', menu1: '4', menu2: '1'})
    }
  },

  async select_current ({ commit }, options) {
    // TODO: Before selecting a current item in a menu level, fetch from server all updated versions of files

    commit('selectCurrent', options)
    // if (options.lesson && getters.) { // If a lesson clicked and accepted

    // }
  },

  async fetchMissingFiles ({ commit, getters }) {

    try {
      const PARALLEL_REQUESTS = envConfig.content.parallelFilesDownload;

      const files = getters.getNotPresentFiles();
      commit('resetMissingFilesDownloadState');
      commit('setMissingFilesDownloadState', { pendingDownload: true, total: files.length, downloaded: 0 });

      console.log(`${files.length} files are missing`);
      console.log('Fetch process started');

      const successfullyDownloadedFiles = [];
      const failedRequests = [];
      let start_index = 0;
      let end_index = PARALLEL_REQUESTS;
      let requestsAmount = files.length;

      while (start_index < end_index && requestsAmount > 0) {
        const reqs = [];
        files.slice(start_index, end_index).forEach(f => reqs.push(fetchFile(f.fileName, f.language)));

        const results = await allSettled(reqs);
        for (let [i, r] of results.entries()) {
          const file = files[start_index + i];

          try {
            if (r.status === 'rejected') {
              failedRequests.push({ status: 'failed to fetch file', filename: file.fileName, reason: r.reason });
              continue;
            }

            const response = r.value;
            if (response.data.size > 0) {
              await File.saveBlob(getters.getRealPath({ file: file.fileName, lang: file.language }), response.data);
              successfullyDownloadedFiles.push(file);
            }

          } catch (e) {
            failedRequests.push({ status: 'failed to save file', filename: file.fileName, reason: e.message });
          }

        }
        commit('updateMissingFilesDownloaded', end_index);
        start_index += PARALLEL_REQUESTS;
        end_index += requestsAmount - end_index >= PARALLEL_REQUESTS ? PARALLEL_REQUESTS : requestsAmount - end_index;
      }

      // Mark file as present
      commit('markFilesPresent', successfullyDownloadedFiles);
      commit('resetMissingFilesDownloadState');

      console.log('Fetch process ended');
      console.log(`Fetched ${files.length - failedRequests.length} files`);
      console.log(`Could not fetch ${failedRequests.length} files`);

    } catch (e) {
      console.log('Fetch missing files error');
      console.log(e);
    }
  },

  // ======================================
  // OFFLINE
  // ======================================

  async loadUserContent({ commit }) {
    try {
      const content = await File.content.load();
      commit('setUserContent', content);
      return Promise.resolve();
    } catch (e) {
      commit('setUserContent', {})
      return Promise.reject(e);
    }
  },

  async loadUserFiles({ commit }) {
    try {
      const files = await File.filetree.load();
      commit('setFiles', files);
      return Promise.resolve();
    } catch (e) {
      commit('setFiles', {})
      return Promise.reject(e);
    }
  },

  async importLocalContent (context, localContentPath) {

    try {
      console.log('~~~~~~~~~~~~~~~~~~~~')
      console.log('Importing content')

      const results = { total: 0, imported: 0, failed: 0 };

      // Create TLS repo
      const tlsRepoPath = File.abs('');
      await fileSystem.mkdirAsync(tlsRepoPath);

      // List source folder files
      const files = await fileSystem.readdirAsync(localContentPath);
      results.total = files.length;
      console.log(`${files.length} files to import`)

      // Move files to TLS repo
      console.log('Import process started')
      for (const file of files) {
        try {
          await fileSystem.copyFileAsync(path.join(localContentPath, file), path.join(tlsRepoPath, file));
          results.imported++;
        } catch (e) {
          console.log(e.message);
          results.failed++;
        }
      }

      console.log('Import process ended')
      console.log(`Imported ${results.imported} files`)
      console.log(`Could not import ${results.failed} files`)
      return Promise.resolve(results);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // Get courses items grouped in products
  // => [{pkey, ptitle, courses: [{ckey, ctitle}]}]
  async getProductCourses ({ rootGetters }) {
    try {
      if (!PRODUCTS_CONTENT.products) { return Promise.resolve([]); }

      const result = Object.keys(PRODUCTS_CONTENT.products)
        .filter(pkey => Object.keys(PRODUCTS_CONTENT.products[pkey].courses || {}).length > 0)
        .map(pkey => { // Get title and courses
          const prd = PRODUCTS_CONTENT.products[pkey]
          const ptitleTranslation = prd.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
          const ptitle = ptitleTranslation ? ptitleTranslation.title : prd.props.title;
          const courses = Object.keys(prd.courses).map(ckey => {
            const crs = prd.courses[ckey]
            const ctitleTranslation = crs.props.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
            const ctitle = ctitleTranslation ? ctitleTranslation.title : crs.props.title;
            return {ckey, ctitle, order: prd.courseOrder.findIndex(i=>i==ckey), weeklyTarget: crs.props.weeklyTarget}
          })
          return {pid: prd.props.id, pkey, ptitle, courses}
        })
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    }
  }

}

function allSettled(promises) {
  let wrappedPromises = promises.map(p => Promise.resolve(p)
    .then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err })));
  return Promise.all(wrappedPromises);
}

async function fetchFile(filename, language) {
  return Promise.resolve(axios.get(`${FILE_SERVER}/files/read?path=${filename}&language=${language}`, { responseType: 'blob'}))
}

// Sync file tree from external resource - Does not inserting or deleting files, only modifying state.local
function syncFileTree (localFileTree, serverFileTree, tlsRepoFileList, language) {
  // Create a tls repository file list map
  const tlsRepoFileListMap = {};
  tlsRepoFileList.forEach(f => tlsRepoFileListMap[f] = 1);

  // Init results
  const results = {
    syncedFileTree: localFileTree,
    filesToDelete: [],
    fileTreeChanged: false,
    filePacksCreatedNow: 0,
    filesCreatedNow: 0,
    filesExistedLocally: 0,
    filesWithDifferentChecksums: 0,
    filesWithSameChecksums: 0,
    filesFoundInFileSystem: 0,
    filesNotFoundInFileSystem: 0,
  };

  // File.present: States if the file is in file system
  // File.synced: States if file's local and server versions are in sync
  // Check files
  Object.keys(serverFileTree).forEach(f => {

    const serverFileChecksum = serverFileTree[f].checksum;
    let localFile;

    // If file does not exist locally, create it
    if (!results.syncedFileTree[f]) { results.syncedFileTree[f] = { language: {} }; results.filePacksCreatedNow++; }
    localFile = results.syncedFileTree[f];

    // From now on `file` references the language specific version of the file

    // If file does not exist locally, create it
    if (!localFile.language[language]) {
      localFile.language[language] = {
        filename: newRandomName(),
        checksum: serverFileChecksum,
        present: false, // Needs to be downloaded
        synced: true // Checksums are synced
      };
      results.fileTreeChanged = true;
      results.filesCreatedNow++;
      return;
    }

    const langFile = localFile.language[language];
    results.filesExistedLocally++;

    // File exists locally. Compare checksums
    // Checksum is different, mark for deletion and re-download
    if (langFile.checksum !== serverFileChecksum) {
      // Mark file for deletion
      results.filesToDelete.push(langFile.filename);

      // Update file info
      langFile.filename = newRandomName();
      langFile.checksum = serverFileChecksum;
      langFile.present = false;
      langFile.synced = true;

      results.fileTreeChanged = true;
      results.filesWithDifferentChecksums++;
    } else { // Checksum is the same
      results.filesWithSameChecksums++;
      // Check if file actually exist in file system
      const fileInFileSystem = tlsRepoFileListMap[langFile.filename] === 1;

      if (fileInFileSystem) {
        results.filesFoundInFileSystem++;
      } else {
        results.filesNotFoundInFileSystem++;
        results.fileTreeChanged = true;
      }
      // Files does not exist in file system
      // if (langFile.present !== fileInFileSystem) { results.fileTreeChanged = true; }

      // Update file info
      langFile.present = fileInFileSystem;
      langFile.synced = true; // Local version is now synced
    }
  });

  return results;
}

export default {
  state,
  mutations,
  getters,
  actions
}



<template>
  <div id="app" class="w-100 p-0 m-0" :class="$t('telestia.app.direction')">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('app/initializeAppState')
    this.$store.dispatch('network/initializeNetworkListener')
    this.$store.dispatch('offline-manager/init')
  },

  computed: {
    userLanguage() { return this.$store.getters['user/getSelectedLanguage']; }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import './styles/app';
@import './styles/spacing';
@import './styles/typography';

#app {
  font-family: 'Roboto', sans-serif;
}

@font-face {
	font-family: 'Lithos Pro';
	src: url('./assets/fonts/lithos/LithosPro-Regular.eot');
	src: url('./assets/fonts/lithos/LithosPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/lithos/LithosPro-Regular.woff') format('woff'),
		url('./assets/fonts/lithos/LithosPro-Regular.ttf') format('truetype'),
		url('./assets/fonts/lithos/LithosPro-Regular.svg#LithosPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
</style>

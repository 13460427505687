<template>

  <div id="landing-page-view2">

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center min-vh-100">
        <app-boot-progress :message="appBootProgressMessage" />
    </div>

    <!-- Loaded state -->
    <div v-else class="loaded-state d-flex flex-column min-vh-100">
      <div v-if="appLoading.state" class="app-loading-overlay">
        <div class="app-loading-overlay-panel p-4 text-center">
          <font-awesome-icon :icon="['fas', 'cut']" size="6x" spin />
          <p class="mt-5">{{ appLoading.message }}</p>
        </div>
      </div>

      <navigation-header class="w-100" />

      <div class="app-content flex-grow-1 d-flex flex-column">
        <router-view />
      </div>

      <navigation-footer class="w-100" />
      
      <scroll-to-top/>
    </div>

  </div>

</template>

<script>
import AppBootProgress from '../../components/shared/AppBootProgress/AppBootProgress';
import NavigationHeader from '../../components/Navigation/Header'
import NavigationFooter from '../../components/Navigation/Footer'
import envConfig from "../../shared/configs/config.local";
import security from "../../shared/helpers/security";
import ScrollToTop from '../../components/shared/ScrollToTop.vue';

export default {
  name: 'landing-page',
  components: {
    AppBootProgress,
    NavigationHeader,
    NavigationFooter,
    ScrollToTop,
  },

  data: function () {
    return {
      appBootProgressMessage: ''
    }
  },

  computed: {

    pendingComponentInit() { return !this.$store.getters.isInitialized; },

    appLoading() {
    	return {
    		state: this.$store.getters["app/getCourseSelectionState"] || 
    			this.$store.getters["app/getCourseProgressFetchState"] || 
    			this.$store.getters["app/getNavigatingToLessonViaHardlink"],
    		message: this.$store.getters["app/getAppLoadingMessage"]
    	}
    }

  },

  created() {
    this.initComponent()
  },

  mounted() {
     const el = document.getElementsByTagName('body')[0];
     const userLevel = this.$store.getters['user/getLevel'];
     if (userLevel>=100) el.classList.add('is-admin');
     if (userLevel>=20) el.classList.add('is-manager');
     if (userLevel>=10) el.classList.add('is-tutor');
  },

  methods: {

    async initComponent() {
      try {
      	// If user is expired, logout
      	if (this.$store.getters['user/isExpired']) { security.logout(); }
      
        await this.$store.dispatch('set_initialized', false);
        let isServerContacted = false;
        const buildType = this.$store.getters['app/buildType'];

        console.time('App initialization');
        let checksum = {};
        switch (buildType) {
          case 'online':
            await this.$store.dispatch('products/fetchProducts');
            await this.$store.dispatch('userCourses/fetchUserCourses');
            await this.$store.dispatch('userOrganisation/fetchOrganisation', this.$store.getters['user/getOrgId']);
            break;
          case 'offline':
            await this.$store.dispatch('loadUserContent');
            await this.$store.dispatch('loadUserFiles');
            break;
          case 'hybrid':
            // Fetch content
            this.appBootProgressMessage = 'Fetching content';
            checksum = await this.$store.dispatch('fetchUserContent');

            // Stop if server could not be contacted
            isServerContacted = this.$store.getters['isServerContacted'];
            // Try loading local cached data and return
            if (!isServerContacted) {
              await this.$store.dispatch('loadUserContent');
              await this.$store.dispatch('loadUserFiles');
              break;
            }

            if (checksum.local === checksum.server) {
            	await this.$store.dispatch('loadUserContent');
            }

            // Fetch general app data
            this.appBootProgressMessage = 'Fetching app data';
            await this.$store.dispatch('fetchFaqs');
            await this.$store.dispatch('fetchGlossary');
            await this.$store.dispatch('fetchInstructions');

            // Fetch files
            this.appBootProgressMessage = 'Checking for new files';
            await this.$store.dispatch('fetchFileTree');
            this.appBootProgressMessage = 'Fetching new files';
            await this.$store.dispatch('fetchMissingFiles');

            // Fetch user data
            await this.$store.dispatch('userCourses/fetchUserCourses');
            await this.$store.dispatch('userOrganisation/fetchOrganisation', this.$store.getters['user/getOrgId']);
            break;
        }

        // Restore previous user session
        if (!this.$route.name.includes('App:Lessons')) { 
        	await this.$store.dispatch("restoreCurrentProductCourseStateProps");
        }

        console.timeEnd('App initialization');
      } catch (e) {
        console.log(e);
      } finally {
        this.appBootProgressMessage = '';
        await this.$store.dispatch('set_initialized', true);
      }
    },

  }
}
</script>


<template>
  <div id="files-download-progress" class="d-flex flex-column align-items-center justify-content-center">
    <!-- Spinner -->
    <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />

    <!-- Progress message -->
    <p class="mt-5 mb-0">{{ message }}</p>

    <!-- Files download progress -->
    <div v-if="pendingMissingFilesDownload" class="text-center mt-5">
      <h5 class="mb-2"> Found {{ missingFilesDownloadState.total }} new files</h5>
      <hr />
      <b-progress variant="primary" max="100" animated striped>
        <b-progress-bar :label="`${downloadProgressValue} %`" :value="downloadProgressValue"></b-progress-bar>
      </b-progress>
    </div>

  </div>
</template>

<script>
export default {

  props: {
    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    pendingMissingFilesDownload() {
      return this.$store.getters.isMissingFilesDownloadPending;
    },

    downloadProgressValue() {
      if (!this.pendingMissingFilesDownload) { return 100; }
      return ((this.missingFilesDownloadState.downloaded / this.missingFilesDownloadState.total) * 100).toFixed(2);
    },

    missingFilesDownloadState() {
      return this.$store.getters.getMissingFilesDownloadState;
    },
  }
}
</script>

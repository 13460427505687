import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";
import { bufferDataToBlob } from '../../shared/helpers/file.js'

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  conversations: [],
  internal: false
}

const getters = {
  getConversations: state => {
    return state.conversations;
  },

  internalConversations: state => {
    return state.internal;
  }
}

const mutations = {
  SET_CONVERSATIONS (state, { conversations, internal }) {
    state.conversations = conversations;
    state.internal = internal;
  },

  SET_MESSAGE_ATTACHMENTS_LOADING_STATE (state, { id, loadingAttachments }) {
    state.conversations = state.conversations.map(currM => {
      return currM.id === id ? { ...currM, loadingAttachments: loadingAttachments } : currM;
    })
  },

  SET_MESSAGE_ATTACHMENTS (state, { id, attachments }) {
  	attachments = attachments.map(a => { return { name: a.file, blob: bufferDataToBlob(a.data.data) } });
    state.conversations = state.conversations.map(currM => {
      return currM.id === id ? { ...currM, attachments: attachments } : currM;
    });
  },

  SET_MESSAGE_ATTACHMENTS_FETCHED_STATE (state, id) {
  	state.conversations = state.conversations.map(currM => {
      return currM.id === id ? { ...currM, attachmentsFetched: true } : currM;
    })
  },
}

const actions = {
  async fetchConversations({ commit }) {
    try {
      // Fetch
      const res = await axios.get(`${FILE_SERVER}/user/messages`);

      const conversations = res.data.messages;
      conversations.forEach(m => {
        m.loadingAttachments = false;
        m.attachmentsFetched = false;
        if (!m.attachments) { m.attachments = []; }
      })

      // Set conversations
      commit("SET_CONVERSATIONS", { conversations, internal: res.data.internal });

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchMessage({ commit }, messageId) {
    try {
      // Fetch message
      const res = await axios.get(`${FILE_SERVER}/user/messages/${messageId}`);

      // Add message attachments
      commit("SET_MESSAGE_ATTACHMENTS", { id: messageId, attachments: res.data.attachments });
      commit("SET_MESSAGE_ATTACHMENTS_FETCHED_STATE", messageId);

      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async sendMessage({ commit }, newMessage) {
    try {
      const res = await axios.post(`${FILE_SERVER}/user/messages`, newMessage, { headers: { "Content-Type": "multipart/form-data" } });
      console.log(res);

      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  setMessageAttachmentsLoadingState ({ commit }, { messageId, state }) {
    commit("SET_MESSAGE_ATTACHMENTS_LOADING_STATE", { id: messageId, loadingAttachments: state });
  }
}

export default { namespaced: true, state, getters, mutations, actions }

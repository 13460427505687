const local = {
  production: true,
  buildType: '',
  FILE_SERVER: 'https://www.fashionexpresslearning.com/fileserver',
  auth: {
    required: {
      login: false,
      activation: false
    },
	singleLogin: true
  },
  content: {
    parallelFilesDownload: 20 // Parallel files download on Hybrid builds. Keep this <50 (or as low as possible) if server struggles
  }
}

// Offline
// local.buildType = 'offline';
// local.auth.required.login = false;
// local.auth.required.activation = false;

// Hybrid
//local.buildType = 'hybrid';
//local.auth.required.login = true;
// local.auth.required.activation = true;

// Online
 local.buildType = 'online';
 local.auth.required.login = true;
 local.auth.required.activation = false;

export default local;

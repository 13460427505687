import axios from "axios";
import envConfig from "../../shared/configs/config.local";
import activation from "../../shared/helpers/activation";
import { version } from '../../../package.json';

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  computerId: null,
  serialNumber: null,
  licenseId: null,
  activationId: null,
  activation: null,
  activated: false,
  fullName: null
}

const actions = {
  clearLicense ({commit}) {
    commit("setComputerId",   null)
    commit("setSerialNumber", null)
    commit("setLicenseId",    null)
    commit("setActivationId", null)
    commit("setActivated",    false)
    commit("setFullName",     null)
  },

  setLicenseData({commit}, params) {
    commit("setComputerId",   params.computerId)
    commit("setLicenseId",    params.licenseId)
    commit("setActivationId", params.activationId)
    commit("setFullName",     params.fullName)
    commit('setLastCheck', params.lastCheck);
  },

  async checkAppActivation({ getters }) {
    try {
      const currentComputerId = await activation.calculateComputerId();
      const computerIdValid = (getters.getComputerId === currentComputerId);
      const activated = activation.checkLicense(getters.getSerialNumber, getters.getComputerId, getters.getLicenseId, getters.getActivationId);
      return Promise.resolve(computerIdValid && activated);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async checkLicense({ getters }) {
    try {
      const payload = {
          activationId: getters.getActivationId,
          version: version
      };
      const response = await axios.post(`${FILE_SERVER}/user/check_license`, payload);
      return Promise.resolve(response.data.status=='ok');
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async deactivateLicense({ getters }) {
    try {
      const payload = {
        serialNumber: getters.getSerialNumber,
        computerId: getters.getComputerId,
        licenseId: getters.getLicenseId
      };
      await axios.post(`${FILE_SERVER}/user/deactivate`, payload);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

const mutations = {
  setComputerId (state, computerId) {
    state.computerId = computerId
  },
  setSerialNumber (state, serialNumber) {
    state.serialNumber = serialNumber
  },
  setLicenseId (state, licenseId) {
    state.licenseId = licenseId
  },
  setActivationId (state, activationId) {
    state.activationId = activationId
  },
  setActivated (state, activated) {
    state.activated = activated
  },
  setFullName (state, fullName) {
    state.fullName = fullName
  },
  setLastCheck(state, lastCheck) {
    state.lastCheck = lastCheck;
  },
}

const getters = {
  getComputerId: (state) => {
    return state.computerId
  },
  getSerialNumber (state) {
    return state.serialNumber
  },
  getLicenseId (state) {
    return state.licenseId
  },
  getActivationId (state) {
    return state.activationId
  },
  getActivation (state) {
    return state.activation
  },
  isActivated (state) {
    return state.activated
  },
  getFullName (state) {
    return state.fullName
  },
  getLastCheck(state) {
    return state.lastCheck;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}

const systemInformation = require("systeminformation");

let info = {};

async function hardwareInfo() {
  if (info.os) return info;
  try {
    const manufacturer = await systemInformation.system();
    const baseboard = await systemInformation.baseboard();
    const cpu = await systemInformation.cpu();
    const memory = await systemInformation.mem();
    const storage = (await systemInformation.diskLayout()).filter(disk => disk.interfaceType!='USB' && disk.type!='Unspecified');
    const os = await systemInformation.osInfo();
    info = {
      manufacturer: `${manufacturer.manufacturer} ${manufacturer.model}`,
      baseboard: `${baseboard.manufacturer} ${baseboard.model}`,
      cpu: `${cpu.manufacturer} ${cpu.brand}`,
      memory: `${(memory.total / Math.pow(1024, 3)).toFixed(1)} GB`,
      storage: `${storage.map(e => `${e.type}: ${e.vendor || 'Unkown'}`)}`,
      os: `${os.platform}, ${os.release}, ${os.arch}`
    }
    return info;
  } catch (e) {
    return Promise.reject(e);
  }
}

export { hardwareInfo };

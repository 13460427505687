import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './translations/en.json'
import store from '../store'
import router from '../router'

Vue.use(VueI18n)

//any .json file in /src/i18n/translations is treated as a defined/available language
const availableLanguages = (function(){
  let files = require.context('./translations/', true, /^.*\.json$/);
  files = files.keys().map(e => require('path').basename(e, '.json'));
  //set 'en' as the first language displayed
  files.unshift(files.splice(files.indexOf('en'), 1)[0]);
  return files;
})();

function setI18nLanguage(lang) {
  i18n.locale = lang;
  const htmlEl = document.getElementsByTagName('html')[0];
  htmlEl.setAttribute('dir', router.app.$t('telestia.app.direction'));
  htmlEl.setAttribute('lang', lang);
  store.dispatch('app/setRtlProp', router.app.$t('telestia.app.direction')=='rtl');
  return lang;
}

function loadLanguageAsync(lang) {
  if (i18n.locale === lang || i18n.availableLocales.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  return import(/* webpackChunkName: "lang-[request]" */ `./translations/${lang}.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    return setI18nLanguage(lang)
  })
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
})

i18n.availableLanguages = availableLanguages
i18n.loadLanguageAsync = loadLanguageAsync

export default i18n

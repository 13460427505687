import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"
import envConfig from "../shared/configs/config.local"
import Layout from '../views/Layout/Layout.view.vue'
import Authorization from "../views/Authorization/Authorization.view.vue"
import security from "../shared/helpers/security";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      // TODO: Find a default path
      { path: '', name: 'App:Main', redirect: { name: 'App:Profile' } },
      { path: '/profile', name: 'App:Profile', component: () => import('../components/Profile/Profile.vue') },
      { path: '/assignments', name: 'App:Assignments', component: () => import('../components/UserProgress/AssignmentUpload.vue') },
      { path: '/content-import', name: 'App:ContentImport', meta: { requiresOffline: true }, component: () => import('../components/shared/AppContentImport/AppContentImport.vue') },
      { path: '/faq', name: 'App:Faq', component: () => import('../components/Faq/Faq.vue') },
      { path: '/glossary', name: 'App:Glossary', component: () => import('../components/Glossary/Glossary.vue') },
      { path: '/how-to-study', name: 'App:HowToStudy', component: () => import('../components/Instructions/Instructions.vue') },
      { path: '/lessons/:product_key/:course_key', name: 'App:Lessons', component: () => import('../views/Product/Product.view.vue'), children: [
          { path: ':lesson_id', name: 'App:Lessons:Lesson', component: () => import('../views/Product/Product.view.vue') }
        ] 
      },
      { path: '/progress', name: 'App:Progress', component: () => import('../components/UserProgress/Progress.vue') },
      { path: '/tutor-talk', name: 'App:TutorTalk', component: () => import('../components/TutorTalk/TutorTalk.vue') },
      { path: '/downloads', name: 'App:Downloads', component: () => import('../components/Downloads/Downloads.vue') },
    ]
  },
  {
    path: '/authorization',
    name: 'authorization-page',
    component: Authorization,
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresNoAuth)) {
    next()
  } else {
    const requiredLogin = envConfig.auth.required.login
    const requiredActivation = envConfig.auth.required.activation
    const loggedIn  = store.getters['user/isAuthenticated']
    const activated = store.getters.isActivated

    if ((requiredLogin && !loggedIn) || (requiredActivation && !activated)) {
      next({
        path: '/authorization',
        params: { nextUrl: to.fullPath }
      })
    } else {
      security.checkLicense();
      security.checkSession();  
      next()
    }
  }
})

export default router

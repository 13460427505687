import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  cds: [],
  currentProductKey: '',
  fetchingProductSkeleton: false,
  currentProductSkeleton: {}
}

const getters = {
  getCds(state) {
    return state.cds;
  },
  
  isProductKeyValid: state => key => {
    const product = state.cds.find(cd => cd.cd.toLowerCase() === key.toLowerCase());
    return product ? true : false;
  },
  
  getProductId(state) {
    return state.cds.find(cd => cd.cd.toLowerCase() === state.currentProductKey.toLowerCase()).id;
  },
  
  getCurrentProductKey(state) {
    return state.currentProductKey;
  },
  
  getFetchingProductSkeleton(state) {
    return state.fetchingProductSkeleton;
  },

  getCurrentProductSkeleton(state) {
    return state.currentProductSkeleton;
  }
}

const mutations = {
  setCds(state, cds) {
    state.cds = cds;
  },

  setCurrentProductKey(state, productKey) {
    state.currentProductKey = productKey;
  },
  
  setFetchingProductSkeleton(state, fetchingState) {
    state.fetchingProductSkeleton = fetchingState;
  },

  setCurrentProductSkeleton(state, skeleton) {
    state.currentProductSkeleton = skeleton;
  }
}

const actions = {
  async fetchProducts({ getters, commit }) {
    try {
      // Return cds if cached
      let cds = getters.getCds;
      if (cds.length > 0) { return Promise.resolve(cds); }

      // Fetch
      const response = await axios.get(`${FILE_SERVER}/products`);
      cds = response.data.cds;

      commit("setCds", cds);
      
      return Promise.resolve();

      // Return products
      // return Promise.resolve(cds);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async fetchProductSkeleton({ getters, commit }, productKey) {
    try {
      // Return skeleton if cached
      const currentProductKey = getters.getCurrentProductKey;
      let currentProductSkeleton = getters.getCurrentProductSkeleton;
      if (Object.keys(currentProductSkeleton).length > 0 && currentProductKey === productKey) { return Promise.resolve(currentProductSkeleton); }

      // Fetch
      commit("setFetchingProductSkeleton", true);
      const response = await axios.get(`${FILE_SERVER}/products/${productKey}/skeleton`);
      currentProductSkeleton = response.data;

      commit("setCurrentProductKey", productKey);
      commit("setCurrentProductSkeleton", currentProductSkeleton);
      commit("setFetchingProductSkeleton", false);
      
      return Promise.resolve();
      
      // Return product skeleton
      // return Promise.resolve(currentProductSkeleton);
    } catch(e) {
      commit("setFetchingProductSkeleton", false);
      return Promise.reject(e);
    }
  }
}

export default { namespaced: true, state, getters, mutations, actions }

import envConfig from '../../shared/configs/config.local.js'
import axios from "axios";

const FILE_SERVER = envConfig.FILE_SERVER;

const state = {
  courses: []
}

const getters = {
  getCourses(state) {
    return  state.courses;
  },
  getCourseId: (state) => (courseKey) => {
    const referencedCourse = state.courses.find(c => c.key === courseKey);
    return referencedCourse ? referencedCourse.id : -1;
  },
  getCourseWeeklyTarget: (state) => (courseTitle) => {
    const referencedCourse = state.courses.find(c => c.title === courseTitle);
    return referencedCourse ? referencedCourse.weeklyTarget : '0';
  },
  getCourseTitle: (state, getters, rootState, rootGetters) => (courseKey) => {
    const referencedCourse = state.courses.find(c => c.key === courseKey);
    const courseTranslation = referencedCourse.translations?.find(tr => tr.language === rootGetters["user/getSelectedLanguage"]);
    const courseTitle = courseTranslation ? courseTranslation.title : referencedCourse.title;
    return courseTitle;
  },
  isCourseExpired: (state, getters, rootState, rootGetters) => (courseKey) => {
    if (rootGetters['user/isAdmin']) { return false; }
    
    const referencedCourse = state.courses.find(c => c.key === courseKey);
    return new Date() > Date.parse(referencedCourse.user_courses.expDate);
  },
  noCourseActiveSubscriptions: (state) => (courseKey) => {
    const referencedCourse = state.courses.find(c => c.key === courseKey);
    return !referencedCourse.user_courses?.subDate && !referencedCourse.user_courses?.subMonths;
  },
  hasActiveCourses: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/isAdmin']) { return true; }
    
    return state.courses.some(c => !getters.isCourseExpired(c.key));
  }
}

const mutations = {
  setUserCourses(state, courses) {
    state.courses = courses;
  }
}

const actions = {
  async fetchUserCourses({ dispatch }) {
    try {
      // Fetch
      const response = await axios.get(`${FILE_SERVER}/user/courses`);
      const userCourses = response.data.courses;

      dispatch("setUserCourses", userCourses);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  setUserCourses({ commit }, courses) {
    commit("setUserCourses", courses)
    let ls = localStorage.getItem("vuex")
    if (!ls) { return; }

    ls = JSON.parse(ls)
    ls.user.session.courses = courses
    localStorage.setItem("vuex", JSON.stringify(ls));
  }
}

export default { namespaced: true, state, getters, mutations, actions }

// Get an <items> array containing menu keys (one for each level)
// Skip the first <skip> items
// For the remaining items, return an array with {name, code} objects
function getLessonItems({ items, skip }) {
  const getIndex = (arr, key) => arr.findIndex(el => el && el.key === key)
  let keys = items
  let r = []
  // TODO: Find out where is this state.menu from
  // let p = state.menu
  let p = { items: [] }
  keys.forEach((key, i) => {
    let k = getIndex(p.items || [], key)
    if (k !== -1) {
      p = p.items[k]
      if (i >= skip) {
        r.push({ name: p.title, code: k })
      }
    }
  })
  return r
}

export function extractLessonCode(titleStr) {
  return titleStr.split(/[ /.]/)[0].trim();
}

export function extractLessonTitle(titleStr) {
  let code = extractLessonCode(titleStr);
  return titleStr.replace(code,'').replace(/^[./]+/g,'').trim();
}

export default { getLessonItems, extractLessonCode, extractLessonTitle }

// import * as types from '../mutation-types'

// const state = {
//   main: 0
// }
//
// const mutations = {
//   [types.DECREMENT_MAIN_COUNTER] (state) {
//     state.main--
//   },
//   [types.INCREMENT_MAIN_COUNTER] (state) {
//     state.main++
//   }
// }

const state = {}
const mutations = {}

export default {
  state,
  mutations
}

<template>
  <nav v-if="!pendingComponentInit && !inLesson" id="app-navigation" class="container-fluid bg-white shadow-sm px-2 py-2" :key="navigationHeaderKey">
  	<div class="row m-0">
      <img class="has-affiliate-logo" height="40" v-if="organisation.logo" src="../../assets/images/logo-watermark.png">
		  <div id="header-main" class="col col-lg-8 mx-auto d-flex justify-content-between align-items-center bg-white">
		    <div class="d-flex">
		      <img class="main-logo mouse-pointer mw-100"
		           :src="organisation.logo ? 'data:image/png;charset=utf-8;base64,'+organisation.logo : require('../../assets/images/logo.png')"
		           :alt="organisation.name + ' ' + ((organisation.footer||{}).description||'')"
		           height="65"
		           @click="navigateToMainView" />
		    </div>
        <div class="d-flex">

          <!-- User menu -->
          <div>
            <b-dropdown ref="userDropdown" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <font-awesome-icon v-if="isLayoutLimited" :icon="['fas', 'user']" class="text-blue" size="lg" />
                <template v-else>
                  <span class="text-blue nav-title">{{ $t("telestia.navigation.hi") }} {{ userName }}</span>
                  <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-orange ml-1" />
                </template>
              </template>
              <b-dropdown-item v-if="!isOfflineBuild" :to="{ name: 'App:Main' }">{{ $t("telestia.navigation.profile") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'App:Faq' }">{{ $t("telestia.navigation.faq") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'App:Glossary' }">{{ $t("telestia.navigation.glossary") }}</b-dropdown-item>
              <b-dropdown-item v-if="!isOfflineBuild" :to="{ name: 'App:TutorTalk' }">{{ $t("telestia.navigation.tutor_talk") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'App:HowToStudy' }">{{ $t("telestia.navigation.instructions") }}</b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item v-if="canDownloadApp" :to="{ name: 'App:Downloads' }">
                {{ $t("telestia.navigation.downloads") }}
                <b-badge v-if="isOnlineBuild" variant="warning"><font-awesome-icon :icon="['fas', 'exclamation']" class="text-black" /></b-badge>
              </b-dropdown-item>
              <b-dropdown-item v-if="!isOnlineBuild" @click="onShowAbout">{{ $t('telestia.navigation.about') }}</b-dropdown-item>
              <!--<b-dropdown-item v-if="showDeactivate" @click="deactivate">{{ $t("telestia.navigation.deactivate") }}</b-dropdown-item>-->
              <b-dropdown-item v-if="showLogout" @click="logout">{{ $t("telestia.navigation.logout") }}</b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Course menu -->
          <div>
            <b-dropdown ref="coursesDropdown" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <font-awesome-icon v-if="isLayoutLimited" :icon="['fas', 'book']" class="text-blue" size="lg" />
                <template v-else>
                  <span class="text-blue nav-title">{{ $t("telestia.navigation.courses") }}</span>
                  <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-orange ml-1" />
                </template>
              </template>
              <template v-for="(prd, prIndex) of productCourses">
                <li class="font-weight-bold px-2 my-1" :key="`pr${prIndex}`">{{ prd.ptitle }}</li>
                <b-dropdown-item v-for="(crs, cIndex) of prd.courses"
                                 :key="`pr${prIndex}-cr${cIndex}`"
                                 :class="{ 'selected': crs.ckey === selectedCourseKey, 'disabled': !isUserAdmin && isOnlineBuild && courseIsExpired(crs.ckey) }"
                                 @click="onCourseSelection({ productKey: prd.pkey, courseKey: crs.ckey }, false)">
                  {{ crs.ctitle }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>

          <!-- User progress menu -->
          <div v-if="!isOfflineBuild">
            <b-dropdown ref="progressDropdown" variant="link" right toggle-class="text-decoration-none" :class="{ 'disabled': !selectedCourseKey }" no-caret>
              <template #button-content>
                <font-awesome-icon v-if="isLayoutLimited" :icon="['fas', 'book-open']" class="text-blue" size="lg" />
                <template v-else>
                  <span class="text-blue nav-title">{{ $t("telestia.navigation.progress") }}</span>
                  <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-orange ml-1" />
                </template>
              </template>
              <b-dropdown-item :to="{ name: 'App:Progress' }">{{ $t("telestia.navigation.todays_class") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'App:Assignments' }">{{ $t("telestia.navigation.assignment_upload") }}</b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Content import -->
          <div v-if="isOfflineBuild" class="d-flex">
            <span class="text-blue nav-title mouse-pointer align-self-center"
                  @click="$router.push({ name: 'App:ContentImport' })"
            >{{ $t("telestia.navigation.import_content") }}</span>
          </div>

          <!-- Languages -->
          <div @mouseenter="showDropdown('flagsDropdown')" @mouseleave="hideDropdown('flagsDropdown')">
            <b-dropdown ref="flagsDropdown" menu-class="flags-dropdown" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <font-awesome-icon v-if="isLayoutLimited" :icon="['fas', 'globe']" class="text-blue" size="lg" />
                <img v-else :src="require(`../../assets/images/flags/${userLanguage}.svg`)" alt="language flag" height="20" width="50" />
              </template>
              <b-dropdown-item  v-for="lang of languages"
                                :key="lang"
                                class="p-1"
                                :class="{ 'selected': lang === userLanguage }"
                                @click="selectLanguage(lang)">
                <div class="d-flex">
                <img :src="require(`../../assets/images/flags/${lang}.svg`)" :alt="`${lang} flag`" height="20" width="50"></div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

        </div>
		  </div>
    </div>

    <about ref="about"></about>

  </nav>

</template>

<script>
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import About from '../shared/About.vue';

export default {
  components: {
    About
  },

  data() {
    return {
      // languages: ['de', 'en', 'es', 'fr', 'el', 'he', 'tr'],
      languages: this.$i18n.availableLanguages,
      productCourses: [],
    }
  },

  created() {
  	this.getProductCourses();
  },

  computed: {

    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isLayoutLimited() { return ['xs', 'sm', 'md'].includes(this.$getViewport()); },

    isUserAdmin() { return this.$store.getters['user/isAdmin']; },

    showLogout() { return !this.isOfflineBuild && this.$store.getters['user/isAuthenticated'] },

    showDeactivate() { return !this.isOnlineBuild && this.$store.getters.isActivated },

    pendingComponentInit() { return !this.$store.getters.isInitialized; },

    inLesson() { return this.$route.name === "App:Lessons:Lesson" && this.$store.getters.getSelected.lessonKey !== null; },

		selectedCourseKey() { return this.$store.getters.getSelected.course; },

    userName() { return this.$store.getters['user/getFirstName'] || this.$store.getters['user/getUsername']; },

    organisation()  { return this.$store.getters['userOrganisation/getOrganisation'] },

    userLanguage: {
      get() { return this.$store.getters['user/getSelectedLanguage']; },
      set(val) { this.$store.commit('user/selectedLanguageSave', val); }
    },

    userLanguages() { return this.$store.getters['user/getLanguages']; },

    navigationHeaderKey() { return this.$store.getters['app/navigationHeaderKey']},

    canDownloadApp() {
      return this.$store.getters['user/canDownloadApp'];
    }

  },

  watch: {
  	$route: {
  		async handler(n, o) {
  			// Move from menu back to lesson
  			if (n.name === 'App:Lessons:Lesson' && o.name === 'App:Lessons') { 
		    	const selected = this.$store.getters.getSelected;
		    	if (n.params.product_key !== selected.productKey.toLowerCase() || n.params.course_key !== selected.course.toLowerCase()) {
		    		const payload = { productKey: n.params.product_key, courseKey: n.params.course_key.toUpperCase() }
		    		await this.onCourseSelection(payload, true);
		    	}
		    	
		    	const lessonId = this.$route.params.lesson_id;
					const lessonKey = this.$store.getters.getLessonCode(lessonId);
					if (lessonKey !== selected.lessonKey) {
						const lessonMenuEntries = this.$store.getters.getLessonMenuEntries(lessonKey);
						let menu1Index = lessonMenuEntries.menu1.index;
						let menu2Index = lessonMenuEntries.menu2.index;
						let previous = {};
						if (!(menu1Index >= 0 || menu2Index >= 0)) {
							const lessonDetails = this.setLessonDetailsUsingMainLesson(lessonId);
							menu1Index = lessonDetails.menu1Index;
							menu2Index = lessonDetails.menu2Index;
							previous = lessonDetails.previous;
						}
						this.selectMenu({ menu1Index, menu2Index, lessonKey });
						if (!_isEmpty(previous)) { this.$store.commit('setPreviousLessonProps', previous); }
					} 
		    }
  			
  			// Move from course to course
		    if (n.name === 'App:Lessons' && o.name === 'App:Lessons') {
		    	const selected = this.$store.getters.getSelected;
		    	if (n.params.product_key !== selected.productKey.toLowerCase() || n.params.course_key !== selected.course.toLowerCase()) {
		    		const payload = { productKey: n.params.product_key, courseKey: n.params.course_key.toUpperCase() }
		    		await this.onCourseSelection(payload, true)
		    	}
		    }
  		}
  	},
  	
    navigationHeaderKey(oldValue, newValue) { if (oldValue !== newValue) { this.getProductCourses(); } }
  },

  methods: {

    showDropdown(refName) {
      if (this.isLayoutLimited) { return; }
      if (this.$refs[refName].$el.classList.contains('disabled')) { return; }
      this.$refs[refName].visible = true;
    },

    hideDropdown(refName) {
      if (this.isLayoutLimited) { return; }
      this.$refs[refName].visible = false;
    },

    onShowAbout() {
      this.$refs.about.show();
    },

    logout() {
      this.$store.dispatch('user/clearSession', {});
      this.selectMenu({ lessonKey: null, menu1Index: null, menu2Index: null });
      this.$store.commit('selectProductCourse', { productKey: null, courseKey: null });
      this.$router.push({ path: '/authorization' })
    },

    // Get courses items grouped in products => [{ pkey, ptitle, courses: [{ ckey, ctitle }] }]
    async getProductCourses() {
      try {
      	let productCourses = [];

        if (!this.isOnlineBuild) {	// Hybrid or desktop build
          productCourses = await this.$store.dispatch('getProductCourses');
        } else {	// Online build
		      const products = this.$store.getters["products/getCds"];
		      productCourses = products.map(p => {
		        const props = p.props || p;
		        const ptitleTranslation = props.translations.find(tr => tr.language === this.userLanguage);
		        const ptitle = ptitleTranslation ? ptitleTranslation.title : props.title;
		        return { pid: p.id, pkey: p.cd, ptitle, courses: [] }
		      });
		      const userCourses = this.$store.getters["userCourses/getCourses"];
		      userCourses.forEach(c => {
		        const pIndex = products.findIndex(p => p.id === c.cdId);
		        if (pIndex !== -1) { productCourses[pIndex].courses.push({ ckey: c.key, ctitle: this.$store.getters['userCourses/getCourseTitle'](c.key), order: c.order }); }
		      })
		      productCourses = productCourses.filter(p => p.courses.length > 0)
		    }

		    productCourses = _sortBy(productCourses, ['pid'])
	      productCourses.forEach(p => p.courses = _sortBy(p.courses, ['order']));
	      this.productCourses = productCourses;
      } catch (e) {
        console.log(e);
      }
  	},

    // Select a course
    async onCourseSelection({ productKey, courseKey }, preventRouteChange) {
    	try {
    		await this.$store.dispatch("app/toggleCourseSelectionState");

    		// In case of online build, fetch product skeleton from server
    		if (this.isOnlineBuild) { await this.$store.dispatch("products/fetchProductSkeleton", productKey.toLowerCase()); }
    		
    		// Select product and course
    		this.$store.commit('selectProductCourse', { productKey, courseKey });

    		// Initialize menu options
    		this.selectMenu({ lessonKey: null });

				// Navigate to Lessons route
        if (!preventRouteChange) {
        	this.$router.push({ name: 'App:Lessons', params: { product_key: productKey.toLowerCase(), course_key: courseKey.toLowerCase() } });
        }
    	} catch (e) {
    		console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.course') + "\n" + this.$t('telestia.error.connection'),
        });
    	} finally {
    		await this.$store.dispatch("app/toggleCourseSelectionState");
    	}
    },

    courseIsExpired(courseKey) {
      return this.$store.getters['userCourses/isCourseExpired'](courseKey);
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    navigateToMainView() {
      this.$router.push({ name: 'App:Main' })
    },

    selectLanguage(lang) {
      this.userLanguage = lang;

      this.$store.dispatch('app/changeAppLocale', this.userLanguage);
      this.getProductCourses();
    },
    
    // Helper function that is used by the $route watcher in case the user navigates from menu to a sublesson
    setLessonDetailsUsingMainLesson(lessonId) {
    	const mainLessonDetails = this.$store.getters.getMainLessonDetails(lessonId);
    	const previous = { key: mainLessonDetails.code, isMainLesson: true, mainLessonPageNum: mainLessonDetails.mainLessonPageNum, hasAssignment: mainLessonDetails.hasAssignment };
    	return { menu1Index: mainLessonDetails.menu1Index, menu2Index: mainLessonDetails.menu2Index, previous };
    },

		showErrorAlert({ title, text }) {
    	this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }

  }
}
</script>

<style scoped>
.has-affiliate-logo {
  width: 70px;
  height: auto;
  position: absolute;
  z-index: 1;
}
#header-main {
  z-index: 998;
}
.nav-title {
  font-size: 16px;
}

</style>

<style lang="scss">
#app-navigation {

  .dropdown .dropdown-toggle:hover {
    font-weight: bold;
    
    svg {
      color: #1b96cf;
    }
  }

  .dropdown .dropdown-toggle:focus {
    box-shadow: none;
  }

  .dropdown-menu {
		[dir=ltr] & { text-align: left !important; }
    [dir=rtl] & { text-align: right !important; }

    background-color: #eee;
    overflow-y: auto;
    max-height: 80vh;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);

    .selected .dropdown-item {
      color: white;
      background-color: #fec801;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: grab !important;
    pointer-events: none;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: white;
    background-color: #fec801;
  }

  .flags-dropdown {
  	min-width: 0 !important;

  	a.dropdown-item {
			width: fit-content;
			padding: 5px 0px;
		}
  }
}
</style>


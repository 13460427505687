import Vue from "vue"

Vue.filter("formatDate", (dateString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const date = new Date(dateString);
  const dayIndex = date.getDate();
  const monthIndex = date.getMonth();
  const monthName = months[monthIndex];
  const year = date.getFullYear();

  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${dayIndex} ${monthName} ${year}, ${hours}:${minutes}`;
})
